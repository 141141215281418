export const Teksten = {
  login: {
    auth: {
      header: "Aanleverloket",
      text: "Documenten publiceren",
      id: "nav_aanleveren_btn",
      title: "Start hier met het publiceren van uw documenten.",
      intro: [
        "U bent ingelogd in het aanleverloket van de Kamerstukkenmodule. Hier kunt u documenten publiceren, wijzigen of intrekken.",
      ],
    },
    nietAuth: {
      header: "Aanleverloket",
      text: "Inloggen",
      id: "inloggen",
      title: "Log in om documenten te publiceren.",
      intro: [
        "Welkom bij het aanleverloket van de Kamerstukkenmodule.",
        "Hier kunt u Kamerstukken en publieke informatie aanleveren die vindbaar wordt gemaakt voor burgers zoals omschreven in de Wet open overheid.",
        " Log in met uw account om voor uw overheidsorganisatie documenten actief openbaar te maken.",
      ],
    },
    storing: {
      header: "Op dit moment ondervinden wij technische problemen",
    },
  },
  publicatie: {
    title: "Publicaties",
    alinia: [
      {
        tekst: ["De ingevoerde overheidsdocumenten worden gepubliceerd op open.overheid.nl en rijksoverheid.nl"],
      },
    ],
  },
  hulp: {
    title: "Hulp en contact",
    alinia: [
      {
        tekst: ["Heeft u nog vragen?", "Mail dan naar servicedesk@koop.overheid.nl "],
      },
      {
        tekst: ["Vermeld s.v.p. in de mail dat het om de nieuwe Kamerstukkenmodule gaat."],
      },
    ],
  },
  sidebardOrganism: {
    reden: {
      title: "Reden voor intrekken",
      tekst: "U staat op het punt om dit document in te trekken.",
      id: "reden",
      label: "Vul hier de reden in:",
      placeholder: "Reden voor intrekken",
    },
  },
  createScreen: {
    dpcupload: {
      tekst: "Dit bestand kan niet verwijderd worden. U kunt wel de metadata of het bestand aanpassen.",
    },
    publisher: {
      id: "publisher",
      label: "Publicerende organisatie",
      placeholder: "Publicerende organisatie",
      tooltip: [
        "Vul hier de overheidsorganisatie in die de wettelijke verantwoordelijkheid draagt voor de actieve openbaarmaking van het document.",
      ],
    },
    documentrelatie: {
      label: "Documentrelatie",
      id_veld1: "documentrelatie_soort",
      id_veld2: "documentrelatie_titel",
      tooltip: ["Vul hier de relatie in die het document heeft met een ander gepubliceerd document."],
    },
    documentstatus: {
      id: "documentstatus",
      label: "Documentstatus",
      tooltip: ["Dit is de status van de verwerking van het document binnen open.overheid.nl"],
    },
    aanleverdatum: {
      id: "aanleverdatum",
      label: "Aanleverdatum",
      tooltip: ["Dit is de datum waarop het document (dus het bestand plus de metadata) is aangeleverd."],
    },
    laatstBewerkt: {
      id: "laatst_bewerkt",
      label: "Laatst bewerkt",
      tooltip: ["Dit is de datum waarop de metadata of het bestand zelf zijn gewijzigd."],
    },
    titel: {
      id: "titel",
      label: "Titel",
      placeholder: "Voer titel in",
      tooltip: [
        "Dit is de titel die op open.overheid.nl wordt getoond bij het document. De titel beschrijft het document op een manier die duidelijk is voor de gebruiker van open.overheid.nl.",
      ],
    },
    documentsoort: {
      id: "documentsoort",
      label: "Documentsoort",
      placeholder: "Voer documentsoort in",
      tooltip: [
        "Om zoekresultaten te kunnen filteren en gebruikers snel inzicht te geven in de aard van het document, moet een documentsoort worden meegegeven.",
      ],
    },
    informatiecategorieen: {
      id: "Woo-informatiecategorie",
      label: "Woo-informatiecategorie",
      placeholder: "Voer Woo-informatiecategorie in",
      tooltip: [
        "Om zoekresultaten effectief te filteren en gebruikers snel inzicht te geven in de inhoud van het document, moet hier een informatiecategorie worden geselecteerd",
      ],
    },
    identifier: {
      id: "identifier",
      label: "Identificatiekenmerk",
      placeholder: "Voer kenmerk of documentnummer in (min. 3 karakters)",
      tooltip: [
        "Vul hier het identificatiekenmerk van het document in (min. 3 karakters). Deze wordt door de aanleverende organisatie zelf aan het document toegekend, en moet binnen de aanleverende organisatie uniek zijn.",
      ],
    },
    documentdatum: {
      label: "Documentdatum",
      tooltip: [
        "De documentdatum geeft aan op welke datum een officiële handeling met betrekking tot het document heeft plaatsgevonden.",
        "Documentdatums zijn samengesteld uit een organisatie die deze handeling heeft uitgevoerd, een handeling en de datum.",
        "Voor gebruik op Rijksoverheid.nl wordt hier minimaal de Vaststellingsdatum toegepast.",
      ],
    },
    wasAssociatedWith: {
      id: "wasAssociatedWith",
      placeholder: "Organisatie",
    },
    soortHandeling: {
      id: "soortHandeling",
      placeholder: "Handeling",
    },
    atTime: {
      id: "atTime",
    },
    taal: {
      id: "taal",
      label: "Taal",
      placeholder: "Taal",
      tooltip: ["Vul hier de taal in waarin het document is geschreven."],
    },
    uitgesteld: {
      id: "uitgesteld",
      label: "Uitgesteld publiceren",
    },
    uitgesteld_tooltip: {
      id: "uitgesteld_publiceren_tooltip",
      tooltip: ["Vul hier het moment in vanaf wanneer het document op zijn vroegst mag worden gepubliceerd."],
    },
    concept: {
      id: "concept",
      label: "Opslaan als concept (BETA)",
    },
    concept_tooltip: {
      id: "concept_publicatie_tooltip",
      tooltip: [
        "Upload de gehele set als concept. Concepten worden niet gepubliceerd en kunnen later gemarkeerd worden als niet concept.",
      ],
    },
    uitsteldatum: {
      id: "uitsteldatum",
      placeholder: "Selecteer datum",
    },
    uitsteltijd: {
      id: "uitsteltijd",
      placeholder: "Selecteer tijd",
    },
    verantwoordelijke: {
      id: "verantwoordelijke",
      label: "Verantwoordelijke organisatie",
      placeholder: "Verantwoordelijke organisatie",
      tooltip: [
        "Vul hier de overheidsorganisatie in die de wettelijke verantwoordelijkheid draagt voor de inhoud/strekking van het document.",
      ],
    },
    medeverantwoordelijken: {
      id: "medeverantwoordelijken",
      label: "Medeverantwoordelijke organisaties",
      placeholder: "Medeverantwoordelijke organisaties",
      tooltip: [
        "Vul hier de overheidsorganisatie(s) in die medeverantwoordelijk is/zijn voor de inhoud/strekking van het document",
      ],
    },
    onderwerpen: {
      id: "onderwerpen",
      label: "Onderwerp",
      placeholder: "Voer onderwerp in (min. 3 karakters)",
      tooltip: [
        "Beschrijf hier in één of enkele woorden waar het document over gaat.",
        "Het vullen van dit veld verbetert de vindbaarheid van het document op open.overheid.nl.",
      ],
    },
    onderwerpenRonl: {
      id: "onderwerpenRonl",
      label: "Onderwerpen RONL",
      placeholder: "Selecteer onderwerpen",
      tooltip: [
        "Om documenten te kunnen ordenen binnen Rijksoverheid.nl wordt gebruik gemaakt van een specifieke onderwerpenlijst.",
      ],
    },
    thema: {
      id: "thema",
      label: "Thema's",
      placeholder: "Selecteer thema('s)",
      tooltip: [
        "Om documenten te kunnen ordenen naar een globaal onderwerp wordt gebruik gemaakt van een themalijst. Een document kan onder meerdere thema’s vallen.",
      ],
    },
    omschrijving: {
      id: "omschrijving",
      label: "Omschrijving document",
      placeholder: "Voer omschrijving in (min. 3 karakters)",
      tooltip: [
        "Beschrijf hier in enkele zinnen waar het document over gaat (min. 3 karakters). De omschrijving verbetert de vindbaarheid van het document op open.overheid.nl en wordt op rijksoverheid.nl gebruikt als inleidende tekst bij het document.",
      ],
    },
    verkorteTitels: {
      id: "verkorteTitels",
      label: "Verkorte titel",
      placeholder: "Voer verkorte titel in (min. 3 karakters)",
      tooltip: ["Geef hier een verkorte aanduiding  (min. 3 karakters) van een mogelijk veel langere officiële titel."],
    },
    alternatieveTitels: {
      id: "alternatieveTitels",
      label: "Alternatieve titel",
      placeholder: "Voer alternatieve titel in (min. 3 karakters)",
      tooltip: [
        "Geef hier een alternatieve titel (min. 3 karakters). Zorg ervoor dat deze anders is dan de officiële of verkorte titel.",

        "Het vullen van dit veld verbetert de vindbaarheid van het document op open.overheid.nl.",
      ],
    },
    naamOpsteller: {
      id: "naamOpsteller",
      label: "Naam opsteller",
      placeholder: "Voer naam opsteller in",
      tooltip: [
        "Vul hier de organisatie in die het document initieel heeft gemaakt. Dit hoeft geen overheidsorganisatie te zijn, maar kan bijvoorbeeld ook een adviesbureau zijn.",
      ],
    },
    creatiedatum: {
      id: "creatiedatum",
      label: "Creatiedatum",
      placeholder: "Selecteer creatiedatum",
      tooltip: [
        "Vul hier de datum in waarop het document initieel is gemaakt. Deze datum kan op de dag nauwkeurig zijn, maar ook een maand of jaar aanduiden.",
      ],
    },
    geldigheid: {
      label: "Geldigheid",
      tooltip: [
        "Vul hier de periode in waarbinnen het document geldig is.",
        "De geldigheid kan gebruikt worden om aan te geven dat een document niet meer geldig is, bijvoorbeeld omdat het door een ander document is vervangen. Het document zal dan niet worden verwijderd, maar het is wel duidelijk dat het niet meer geldig is.",
      ],
    },
    begindatum: {
      id: "begindatum",
      placeholder: "Selecteer begindatum",
    },
    einddatum: {
      id: "einddatum",
      placeholder: "Selecteer einddatum",
    },
    aggregatiekenmerk: {
      id: "aggregatiekenmerk",
      label: "Aggregatiekenmerk",
      placeholder: "Voer aggregatiekenmerk in",
      tooltip: [
        "Vul hier de door aanleverende organisatie aangedragen tekst in, die een aantal documenten met elkaar gemeen kunnen hebben.",
      ],
    },
    reden: {
      id: "reden",
      label: "Vul hier de reden in:",
      placeholder: "Reden voor vervangen",
    },
  },
  over: {
    titel: "Meer informatie over het aanleverloket",
    alinia: [
      {
        titel: "De Kamerstukkenmodule",
        tekst: [
          "Het aanleverloket van de Kamerstukkenmodule kan worden gebruikt voor het actief openbaar maken van overheidsdocumenten. Documenten kunnen via het aanleverloket handmatig worden gepubliceerd. ",
          "De gepubliceerde documenten worden vervolgens verwerkt en getoond op de website open.overheid.nl. ",
          "Ook biedt de Kamerstukkenmodule de mogelijkheid om Kamerstukken en niet-Kamerstukken openbaar",
          " te maken op de website Rijksoverheid.nl.",
        ],
      },
      {
        titel: "Hulp",
        tekst: [
          "Ik heb per ongeluk het verkeerde document geüpload in het aanleverloket van de Kamerstukkenmodule, wat nu? ",
          "In het aanleverloket van de Kamerstukkenmodule is het mogelijk om bestanden te bewerken, in te trekken en/of opnieuw te publiceren. ",
        ],
        linkTekst: "Meer uitleg over het aanleverloket staat beschreven in de ",
      },
      {
        titel: "Contact",
        tekst: [
          "Voor vragen of meer informatie kunt u mailen naar servicedesk@koop.overheid.nl. ",
          "Vermeld s.v.p. in de mail dat het om de nieuwe Kamerstukkenmodule gaat.",
        ],
      },
    ],
  },
};

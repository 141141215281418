import { Links, Teksten } from "../../../content";
import { TekstArray } from "../../../models/teksten";
import { ContainerAtom, IconAtom, LinkAtom, SpacerAtom } from "../../atoms";
import { TypographyAtom } from "../../atoms/typography";
import { JumbotronMolecule } from "../../molecules";

export function HulpContact() {
  const intro = (
    <ContainerAtom type="columns">
      <SpacerAtom space={8} />
      {Teksten.hulp.alinia.map((tekst:TekstArray, index:number) => {
        return (
          <div key={"hulp_contact_tekst_" + index}>
            <TypographyAtom>{tekst.tekst}</TypographyAtom>
            <SpacerAtom
              space={index === Teksten.hulp.alinia.length - 1 ? 8 : 4}
            />
          </div>
        );
      })}
      <ContainerAtom>
        <LinkAtom {...Links.intern.over} />
        <IconAtom icon={"icon-cta-right"} size={"medium"} alt={"update"} />
      </ContainerAtom>
    </ContainerAtom>
  );

  return <JumbotronMolecule header={Teksten.hulp.title} intro={intro} />;
}

import { Teksten } from "../../../content";
import { Melding } from "../../../models/melding";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { login } from "../../../store/reducers/auth";
import { selectAuth, selectMelding } from "../../../store/selectors";
import { ContainerAtom } from "../../atoms";
import { ButtonMolecule, JumbotronMolecule } from "../../molecules";
import { HulpContact } from "../hulp-contact";
import { Publicatie } from "../publicatie";

export function LoginFormOrganism() {
  const dispatch = useAppDispatch();
  const { fetching } = useAppSelector(selectAuth);
  const { meldingen } = useAppSelector(selectMelding);
  const onAuthenticate = () => {
    dispatch(login());
  };

  return (
    <div>
      <JumbotronMolecule
        center
        header={Teksten.login.nietAuth.header}
        link={
          <ButtonMolecule
            id={Teksten.login.nietAuth.id}
            title={Teksten.login.nietAuth.title}
            loading={fetching}
            text={Teksten.login.nietAuth.text}
            type="primary"
            onClick={onAuthenticate}
          />
        }
        intro={Teksten.login.nietAuth.intro}
      />
      <>
        {
          meldingen.map((melding: Melding) => {
            return <JumbotronMolecule
              center
              header={Teksten.login.storing.header}
              intro={melding.omschrijving}
              type="warning"
            />;
          })}
      </>

      <ContainerAtom type="row">
        <Publicatie />
        <HulpContact />
      </ContainerAtom>
    </div>
  );
}

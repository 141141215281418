import styles from "./styles.module.scss";

interface Props {
  children?: any[];
}

export function TypographyAtom(props: Props) {
  const className = `${styles.typography}`;

  const returnValue = props.children && props.children.map((child:any, index:number) => {
      return <p key={"child_"+index} className={className}>{child}</p>;
    });
    
  return <>{returnValue}</>;
}

import { DefaultLayout } from "../../layouts";
import {
  OverheidHeaderOrganism,
  StartOrganism,
  ToastOrganism,
} from "../../organisms";

export default function StartScreen() {

  return (
    <DefaultLayout
      header={
        <>
          <ToastOrganism />
          <OverheidHeaderOrganism />
        </>
      }
      body={<StartOrganism />}
    />
  );
}



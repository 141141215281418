import { SidebarLayout } from "../../layouts";
import {
  CreateFormulierOrganism, OverheidHeaderOrganism, SidebarOrganism, ToastOrganism
} from "../../organisms";

export default function CreateScreen() {
  return (
    <SidebarLayout
      sidebar={<SidebarOrganism />}
      header={
        <>
          <ToastOrganism />
          <OverheidHeaderOrganism />
        </>
      }
      body={<CreateFormulierOrganism />}
    />
  );
}

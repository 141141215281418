import { Melding } from '../models';
import { BaseClient } from './baseClient';

export interface IMeldingApi {
  GetMeldingen(): Promise<Melding[]>;
}

export class MockMeldingApi implements IMeldingApi {

  GetMeldingen = async () => {
    const melding = [{id: "001", omschrijving: "test melding 1"}];
    return melding as Melding[];
  };

}

export class MeldingApi extends BaseClient implements IMeldingApi {
  constructor() {
    super(
      new Headers({
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      }),
      process.env.REACT_APP_MELDINGEN as string
    );
  }

  GetMeldingen = async () => {
    const { json } = await this.api<Melding[]>(
      'meldingen',
      undefined,
      'GET'
    );

    return json as Melding[];
  };
}

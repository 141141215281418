export const Links = {
  intern: {
    start: {
      href: "#/startpagina",
      text: "Startpagina",
      title: "Naar de startpagina.",
    },
    documentenlijst: {
      href: "#/documenten",
      text: "Documentenlijst",
      title: "Naar de documentenlijst.",
    },
    aanleveren: {
      href: "#/aanleveren",
      text: "Documenten publiceren",
      title: "Start hier met het publiceren van uw documenten.",
    },
    over: {
      href: "#/over",
      text: "Over het aanleverloket ",
      title: "Lees hier meer over het aanleverloket.",
    },
    apidocs: {
      href: "#/api-doc",
      text: "API Documentatie",
      title: "Bekijk de API documentatie.",
    },
  },
  extern: {
    handleiding: {
      href: "https://www.koopoverheid.nl/documenten/instructies/2023/05/11/handleiding-kamerstukkenmodule",
      text: "handleiding Kamerstukkenmodule.",
      title: "Lees hier meer over het aanleverloket.",
    },
    portaal: {
      href: "https://open.overheid.nl",
      text: "Ga naar het portaal ",
      title: "Op het portaal kunt u de aangeleverde documenten vinden.",
    },
  },
};

import styles from './styles.module.scss';

interface Props {
    type: 'primary' | 'white'
    size?: 'extraSmall' | 'small' | 'large'
}

export function SpinnerAtom(props: Props) {
    const styleType = styles[`spinner__${props.type}`];
    let className = `${styleType}`;
    if(props.size){
        const styleSize = styles[`spinner__${props.size}`];
        className += ` ${styleSize}`;
    }
    else {
        const styleSmall = styles[`spinner__small`];
        className += ` ${styleSmall}`;
    }

    return <div data-testid={"spinner"} className={styles.spinner}><div className={className} /></div>

}
import { useState } from "react";
import { Melding } from "../../../models/melding";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { login, logout } from "../../../store/reducers/auth";
import { selectAuth, selectMelding } from "../../../store/selectors";
import {
  BannerStrokeAtom,
  ContainerAtom,
  DesktopHiddenAtom,
  HeaderAtom,
  IconAtom,
  LogoAtom,
  MobileHiddenAtom
} from "../../atoms";
import { IExternalLink } from "../../interfaces/Link";
import { IconButtonMolecule, ListMolecule } from "../../molecules";
import styles from "./styles.module.scss";

interface Props {
  links: IExternalLink[];
  logo: string;
  subtitle: string;
}

export function HeaderMolecule(props: Props) {
  const state = useAppSelector(selectAuth);
  const meldingState = useAppSelector(selectMelding);
  const dispatch = useAppDispatch();

  const [menuOpened, setMenuOpened] = useState(false);

  let classNameNav = `${styles.header__nav}`;

  if (menuOpened) {
    const stylesMenuOpen = styles[`header__nav--menuOpened`];
    classNameNav += ` ${stylesMenuOpen}`;
  }

  return (
    <>
      <HeaderAtom>
        <div className={styles.header__start}>
          <ContainerAtom centeredHorizontal type="row">
            <div>
              <LogoAtom alt="Overheid logo" size="large" src={props.logo} />
              <p>{props.subtitle}</p>
            </div>
            <ContainerAtom type="row">
              <div className={styles.header__start__right}>
                {state.isAuthenticated && (
                  <>
                    <IconAtom
                      icon={"icon-profile"}
                      size={"medium"}
                      alt={"icon-profile"}
                    />
                    <p>{state.organisatie[0]?.label}</p>
                    <IconButtonMolecule
                      id={"uitloggen_button"}
                      title="Log uit om uw sessie te beëindigen."
                      onClick={() => {
                        dispatch(logout());
                      }}
                      text={"Uitloggen"}
                      type={"default"}
                      icon={"icon-logout"}
                    />
                  </>
                )}
                {state.isAuthenticated === false && (
                  <>
                    <IconButtonMolecule
                      id={"inloggen_button"}
                      title="Log in om documenten te publiceren."
                      onClick={() => dispatch(login())}
                      text={"Inloggen"}
                      type={"default"}
                      icon={"icon-logout"}
                    />
                  </>
                )}
                <DesktopHiddenAtom>
                  <IconButtonMolecule
                    rtl
                    id="icon-hamburger"
                    title="Menu"
                    text="Menu"
                    type="default"
                    icon={"icon-hamburger"}
                    onClick={() => {
                      setMenuOpened(!menuOpened);
                    }}
                  />
                </DesktopHiddenAtom>
              </div>
            </ContainerAtom>
          </ContainerAtom>
        </div>
        <nav className={classNameNav}>
          <ContainerAtom centeredHorizontal>
            <MobileHiddenAtom>
              <div className={styles.flex}>
                <ListMolecule unstyled links={props.links} />
              </div>
            </MobileHiddenAtom>

            <DesktopHiddenAtom>
              {menuOpened && <ListMolecule unstyled links={props.links} />}
            </DesktopHiddenAtom>
          </ContainerAtom>
        </nav>
        {meldingState.meldingen.map((melding: Melding) => {
          return <BannerStrokeAtom key={melding.omschrijving} >{melding.omschrijving}</BannerStrokeAtom>;
        })}
      </HeaderAtom>
    </>
  );
}

import { Identifier } from "../models";
import { StringSanitizer } from "../utils/StringSanitizer";

const themalijst = require("../assets/waardelijsten/themas.json");
const talenlijst = require("../assets/waardelijsten/talen.json");
const redenlijst = require("../assets/waardelijsten/ccw_plooi_reden_intrekking_of_vervanging_1.json");
const ministerielijst = require("../assets/waardelijsten/rwc_ministeries_compleet_1.json");
const documentsoortenlijst = require("../assets/waardelijsten/documentsoorten.json");
const documenthandelingenlijst = require("../assets/waardelijsten/documenthandelingen.json");
const informatiecategorieen = require("../assets/waardelijsten/scw_woo_informatiecategorieen_4.json");

export interface IdentifierWithChildren extends Identifier {
  children?: IdentifierWithChildren[];
}

export interface WaardelijstItem {
  item: Identifier;
  children?: WaardelijstItem[];
}

export class WaardelijstFactory {
  private sanitizer: StringSanitizer;

  constructor() {
    this.sanitizer = new StringSanitizer();
  }

  private mapWaardelijstItem = (item: any, ctx: any): IdentifierWithChildren => ({
    id: this.sanitizer.sanitizeLdId(item, ctx),
    label: this.sanitizer.sanitizeLdPrefLabel(item),
  });

  private traverseTree = (data: any[], ctx: any): IdentifierWithChildren[] => {
    const items = [...data];
    let tree = items.map((item: any) => this.mapWaardelijstItem(item, ctx));

    items.forEach((originalItem: any) => {
      const mappedItem = this.mapWaardelijstItem(originalItem, ctx);

      if (!originalItem.broader) return;

      const parent = tree.find(
        (iwc: IdentifierWithChildren) => iwc.id === this.sanitizer.sanitizeLdId(originalItem["broader"], ctx)
      );

      if (!parent) return;

      if (!parent.children) parent.children = [];

      parent.children.push(mappedItem);
      tree = tree.filter((iwc: IdentifierWithChildren) => iwc.id !== mappedItem.id);
    });

    return tree;
  };

  private getFlatSkosTree = (
    waardelijst: any[],
    labelIdentifier: string,
    invalidIdentifier: string
  ): IdentifierWithChildren[] => {
    return waardelijst
      .map((waardelijstItem: { [x: string]: { [x: string]: string }[] }) => {
        if (
          !waardelijstItem.hasOwnProperty("@id") ||
          !waardelijstItem.hasOwnProperty(labelIdentifier) ||
          waardelijstItem.hasOwnProperty(invalidIdentifier)
        )
          return undefined;

        const item: any = { id: waardelijstItem["@id"] };
        if (waardelijstItem.hasOwnProperty(labelIdentifier))
          item.label = waardelijstItem[labelIdentifier][0]["@value"] ?? undefined;

        return item;
      })
      .filter((item: any) => item !== undefined);
  };

  private getSkosTree = (waardelijst: any[], tree: IdentifierWithChildren[]) => {
    const markedForDelete: string[] = [];

    waardelijst.forEach((waardelijstItem: any) => {
      const itemId = waardelijstItem["@id"];

      const parent = waardelijst.find((i) => {
        const members = i["http://www.w3.org/2004/02/skos/core#member"];
        return members?.some((member: any) => member["@id"] === waardelijstItem["@id"]);
      });

      //Zelf parent
      if (!parent) return;

      const parentId = parent["@id"];
      const parentInTree = tree.find((iwc: IdentifierWithChildren) => iwc.id === parentId);
      const childInTree = tree.find((iwc: IdentifierWithChildren) => iwc.id === itemId);

      if (!parentInTree || !childInTree) return;

      if (!parentInTree.children) parentInTree.children = [];

      parentInTree.children.push(childInTree);
      markedForDelete.push(childInTree.id);
    });

    let newTree = tree
      .filter((iwc: IdentifierWithChildren) => markedForDelete.every((mark: string) => mark !== iwc.id))
      .filter((item: IdentifierWithChildren) => item.children && item.children.length > 0);

    return newTree;
  };

  private traverseTreeSkos = (
    waardelijst: any[],
    labelIdentifier: string,
    invalidIdentifier: string
  ): IdentifierWithChildren[] => {
    const allParentItems = waardelijst.filter((list: { hasOwnProperty: (arg0: string) => any }) =>
      list.hasOwnProperty("http://www.w3.org/2004/02/skos/core#member")
    );

    let tree: IdentifierWithChildren[] = this.getFlatSkosTree(waardelijst, labelIdentifier, invalidIdentifier);

    if (allParentItems.length === 0) return tree;

    let newTree = this.getSkosTree(waardelijst, tree);

    if (newTree.length === 1) {
      const topParentSelectableChildren = newTree[0].children!.every((iwc: IdentifierWithChildren) => iwc.children);
      if (newTree[0].children && topParentSelectableChildren) {
        newTree = newTree[0].children;
      } else {
        newTree[0].label = "";
      }
    }

    return newTree;
  };

  private UriLabelLijst = (jsonLijst: any[]) => {
    return jsonLijst.map((lijstItem: any) => {
      return { id: lijstItem.uri, label: lijstItem.label };
    });
  };

  private graphContextlijst = (jsonLijst: any): IdentifierWithChildren[] => {
    const data = jsonLijst["@graph"];
    const context = jsonLijst["@context"];
    return this.traverseTree(data, context);
  };

  private skosLijst = (jsonLijst: any[], invalid: boolean = true): IdentifierWithChildren[] => {
    const prefLabel = "http://www.w3.org/2004/02/skos/core#prefLabel";
    const rdfLabel = "http://www.w3.org/2000/01/rdf-schema#label";
    const invalidlabel = "http://www.w3.org/ns/prov#invalidatedAtTime";

    if (jsonLijst.length === 0) return [];

    let labelIdentifier = "";

    if (jsonLijst[0].hasOwnProperty(prefLabel)) labelIdentifier = prefLabel;
    else if (jsonLijst[0].hasOwnProperty(rdfLabel)) labelIdentifier = rdfLabel;

    const lijst = this.traverseTreeSkos(jsonLijst, labelIdentifier, invalid ? invalidlabel : "");
    return lijst;
  };

  public createThemaLijst = () => this.graphContextlijst(themalijst);
  public createTaalLijst = () => this.skosLijst(talenlijst);
  public createRedenLijst = () => this.skosLijst(redenlijst);
  public createMinisterieLijst = (invalid: boolean = true) => this.skosLijst(ministerielijst, invalid);
  public createFromTooi = (payload: any) => this.UriLabelLijst(payload);

  public createDocumentsoortenLijst = (isKST: boolean = false) => {
    const items = this.skosLijst(documentsoortenlijst);
    const itemWithKamerstuk = items[0].children?.find(
      (iwc: IdentifierWithChildren) => iwc.label === "parlementair document"
    );
    if (itemWithKamerstuk && !isKST)
      itemWithKamerstuk.children = itemWithKamerstuk.children?.filter(
        (iwc: IdentifierWithChildren) => iwc.label !== "Kamerstuk"
      );

    return items;
  };
  public createDocumenthandelingenLijst = () => this.skosLijst(documenthandelingenlijst);
  public createInformatiecategorieenLijst = () => this.skosLijst(informatiecategorieen);
}

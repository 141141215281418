import { ContainerAtom } from "../container";
import styles from "./styles.module.scss";

export enum RowState {
  default,
  error,
  success,
  uploading
}

export interface TableRowState {
  state : RowState;
  index : number;
}

interface Props {
  theads: any[];
  rows: any[][];
  columnWidth?: string[];
  highlight?: number;
  states?: TableRowState[];
}

export function TableAtom(props: Props) {
  const headerRow = props.theads.map((i:any, index:number) => {
    return (
      <th
        key={i + index}
        className={styles.header_cell}
        style={
          props.columnWidth && index <= props.columnWidth.length
            ? { width: props.columnWidth[index] }
            : {}
        }
      >
        <div className={styles.item}>{i}</div>
      </th>
    );
  });

  const rows = props.rows.map((column:any[], indexRow:number) => {
    let classname : string = '';

    if(props.highlight !== undefined && indexRow === props.highlight) {
      classname += ` ${styles.highlight}`;
    }

    if(props.states != null) {
      const state = props.states.find((rowState : TableRowState) => rowState.index === indexRow)?.state;
      switch(state) {
        case RowState.uploading:
          classname += ` ${styles.uploading}`;
          break;
        case RowState.error:
          classname += ` ${styles.error}`;
          break;
        case RowState.success:
          classname += ` ${styles.success}`;
        break;
        case RowState.default:
        default:
          break;
      }
    }

    return <tr key={indexRow} className={classname}>
      {column.map((col:any, index:number) => {
        return (
          <td
            key={index}
            className={styles.cell}
            style={
              props.columnWidth && index <= props.columnWidth.length
                ? { width: props.columnWidth[index] }
                : {}
            }
          >
            <div className={styles.item}>{col}</div>
          </td>
        );
      })}
    </tr>;
});

  return (
    <ContainerAtom>
      <div className={styles.container}>
        <table>
          <tbody>
            <tr key='header' className={styles.header}>{headerRow}</tr>
            {rows}
          </tbody>
        </table>
      </div>
    </ContainerAtom>
  );
}

import { ChangeEvent } from "react";
import styles from "./styles.module.scss";

interface Props {
  placeholder: string;
  rows?: number;
  readonly?: boolean;
  id?: string;
  value: string;
  error?: boolean;
  onBlur?: () => void;
  onChange?: (text: string) => void;
  disabled?: boolean;
}

export function TextAreaAtom(props: Props) {
  let className = `${styles["textarea"]}`;
  if (props.error) className += ` ${styles["textarea--alert"]}`;
  if (props.disabled) className += ` ${styles["textarea--disabled"]}`;

  const handleChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    if (!props.onChange) return;
    props.onChange(ev.currentTarget.value);
  };

  return (
    <textarea
      disabled={props.disabled}
      value={props.value}
      onChange={handleChange}
      onBlur={props.onBlur}
      id={props.id}
      readOnly={props.readonly}
      className={className}
      placeholder={props.placeholder}
      rows={props.rows ?? 5}
    />
  );
}

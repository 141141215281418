import { Identifier } from "../models";
import { IdentifierMetAfkortingActief } from "../models/identifier";
import { ICategoryList } from "../ui/interfaces/CategoryList";
import { ICheckbox } from "../ui/interfaces/Checkbox";
import { StringSanitizer } from "../utils/StringSanitizer";
import { CheckboxFactory } from "./checkbox";
import { IdentifierWithChildren } from "./waardelijst";

export class DropdownInfoFactory {
    private cbFactory: CheckboxFactory;
    private sanitizer: StringSanitizer;

    constructor() {
        this.sanitizer = new StringSanitizer();
        this.cbFactory = new CheckboxFactory();
    }

    generateCategoryListSelect(filterParentsWithoutChildren: boolean, item: IdentifierWithChildren, selectedValues: Identifier[]): ICategoryList {
        let list: ICategoryList = {
            title: this.sanitizer.capitalizeFirstLetter(item.label!),
            options: !item.children ? [] : item.children.filter((iwc:IdentifierWithChildren) => !iwc.children || !filterParentsWithoutChildren).map((subItem:IdentifierWithChildren) => this.cbFactory.create(
                this.sanitizer.sanitizeWaardelijstItem(subItem),
                selectedValues.some((identifier:Identifier) => this.selectedCheckbox(identifier, {id: subItem.id, label:subItem.label} as Identifier)),
                false,
                false
            )),
            sublist: item.children?.filter((iwc:IdentifierWithChildren) => iwc.children).map((subItem:IdentifierWithChildren) => this.generateCategoryListSelect(filterParentsWithoutChildren, subItem, selectedValues)),
        };

        return list;
    }

    generateCategorlyListSelectFromList(items: IdentifierWithChildren[] | IdentifierWithChildren, selectedItems: Identifier[] | Identifier, filterParentsWithoutChildren?: boolean): ICategoryList[] {
        const selectedItemList = Array.isArray(selectedItems) ? selectedItems : [selectedItems];
        const ItemList = Array.isArray(items) ? items : [items];
        return ItemList.map((iwc:IdentifierWithChildren) => this.generateCategoryListSelect(filterParentsWithoutChildren ?? true, iwc, selectedItemList));
    }

    generateCheckboxListSelect(items: Identifier[] | IdentifierMetAfkortingActief[], selectedItems?: Identifier[] | Identifier, filter?: (checkbox: ICheckbox) => boolean): ICategoryList[] {
        const selectedItemsDefined = selectedItems ?? [];
        const selectedItemList = Array.isArray(selectedItemsDefined) ? selectedItemsDefined : [selectedItemsDefined];
        const checkboxes: ICheckbox[] = [];
        for (const element of items) {
            const item = element;
            const disabled = (item as IdentifierMetAfkortingActief).disabled;
            const selected = selectedItemList.some((identifier:Identifier) => this.selectedCheckbox(identifier, item));
            checkboxes.push({
                checked: selected,
                id: item.id,
                label: item.label ?? "",
                disabled: disabled ?? false
            });
        }
        const filteredCheckboxes = filter ? checkboxes.filter((checkbox:ICheckbox) => filter(checkbox)) : checkboxes;
        return [{ options: filteredCheckboxes }];
    }

    selectedCheckbox = (item1:Identifier, item2: Identifier | IdentifierMetAfkortingActief):boolean => {
        if(item1.label !== undefined && item2.label !== undefined)
            return item1.id === item2.id && item1.label === item2.label;

        return item1.id === item2.id;
    }
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiFactory } from "../../api";
import {
  SearchResult,
  SearchResults,
  SelectedRow,
} from "../../models/search-result";
import { Filters, Sorting, SortingOrder } from "../../models/sorting";
import { ReducerErrorToaster } from "../../utils/ReducerUtils";

interface SearchState {
  data: SearchResults;
  filters: Filters | undefined;
  sorting: Sorting;
  sortingOrder: SortingOrder;
  search: string;
  fetching: boolean;
  fetchingBijlage: boolean;
  rowsUnfolded: SelectedRow[];
  selectedConcepts: SearchResult[];
}

const initialState: SearchState = {
  fetching: false,
  fetchingBijlage: false,
  data: {
    aantal: 0,
    resultaten: [],
    start: 0,
    totaal: 0,
  },
  filters: "hoofddocument",
  sorting: "laatst-bewerkt",
  sortingOrder: "desc",
  search: "",
  rowsUnfolded: [],
  selectedConcepts: [],
};

export const searchDocuments = createAsyncThunk(
  "search/get",
  async (_, thunkAPI) => {
    const api = ApiFactory.createSearchApi();
    const state: SearchState = (thunkAPI.getState() as any).search;

    try {
      return await api.searchOrgDocuments(
        state.data.resultaten.length,
        state.sorting,
        state.filters,
        state.sortingOrder,
        state.search
      );
    } catch (error: any) {
      ReducerErrorToaster(thunkAPI, error);
      throw error;
    }
  }
);

export const searchBijlages = createAsyncThunk(
  "metadata/getBijlages",
  async (
    args: {
      uuid: string;
      rowIndex: number;
    },
    thunkAPI
  ) => {
    const api = ApiFactory.createMetadataApi();
    const state: SearchState = (thunkAPI.getState() as any).search;

    let newSelectedRows: SelectedRow[] = [...state.rowsUnfolded];

    if (
      state.rowsUnfolded.find(
        (f) =>
          f.index === args.rowIndex &&
          f.foldableContent &&
          f.foldableContent.length > 0
      )
    ) {
      newSelectedRows = state.rowsUnfolded.filter(
        (f) => f.index !== args.rowIndex
      );
    } else {
      const results = await api
        .getDocumentBijlages(args.uuid)
        .catch((error) => {
          ReducerErrorToaster(thunkAPI, error);
          throw error;
        });
      if (newSelectedRows.find((f) => f.index === args.rowIndex)) {
        const newRow = { index: args.rowIndex, foldableContent: results };
        newSelectedRows = newSelectedRows.filter(
          (f) => f.index !== args.rowIndex
        );
        newSelectedRows.push(newRow);
      } else
        newSelectedRows.push({
          index: args.rowIndex,
          foldableContent: results,
        });
    }
    return newSelectedRows;
  }
);

const searchState = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.data.aantal = 0;
      state.data.resultaten = [];
      state.data.start = 0;
      state.data.totaal = 0;
    },
    updateSorting: (state, action: PayloadAction<Sorting>) => {
      state.sorting = action.payload;
    },
    updateSortingOrder: (state, action: PayloadAction<SortingOrder>) => {
      state.sortingOrder = action.payload;
    },
    updateSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFilter: (state, action: PayloadAction<Filters | undefined>) => {
      if (action.payload === undefined) state.rowsUnfolded = [];

      state.filters = action.payload;
    },
    clearRowsUnfolded: (state, action: PayloadAction) => {
      state.rowsUnfolded = [];
    },
    updateSelectedConcepts: (state, action: PayloadAction<SearchResult>) => {
      if (
        state.selectedConcepts.find(
          (result: SearchResult) =>
            result.document.pid === action.payload.document.pid
        )
      )
        state.selectedConcepts = state.selectedConcepts.filter(
          (result: SearchResult) =>
            result.document.pid !== action.payload.document.pid
        );
      else state.selectedConcepts.push(action.payload);
    },
    clearSelectedConcepts: (state, action: PayloadAction) => {
      state.selectedConcepts = [];
    },
  },
  extraReducers: (caseBuilder) => {
    caseBuilder.addCase(searchDocuments.pending, (state, action) => {
      state.fetching = true;
    });
    caseBuilder.addCase(searchDocuments.rejected, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(searchDocuments.fulfilled, (state, action) => {
      state.fetching = false;
      state.data = {
        ...action.payload,
        resultaten: [...state.data.resultaten, ...action.payload.resultaten],
      };
    });
    caseBuilder.addCase(searchBijlages.pending, (state, action) => {
      state.fetchingBijlage = true;
      state.rowsUnfolded.push({
        index: action.meta.arg.rowIndex,
        foldableContent: [],
      } as SelectedRow);
    });
    caseBuilder.addCase(searchBijlages.rejected, (state, action) => {
      state.fetchingBijlage = false;
      state.rowsUnfolded = state.rowsUnfolded.filter(
        (f) => f.index !== action.meta.arg.rowIndex
      );
    });
    caseBuilder.addCase(searchBijlages.fulfilled, (state, action) => {
      state.fetchingBijlage = false;

      state.rowsUnfolded = action.payload as SelectedRow[];
    });
  },
});

export const {
  clearSearch,
  updateSorting,
  updateSortingOrder,
  updateSearch,
  updateFilter,
  clearRowsUnfolded,
  clearSelectedConcepts,
  updateSelectedConcepts,
} = searchState.actions;
export default searchState.reducer;

import { IconAtom, LabelAtom } from "../../atoms";
import { IToggle } from "../../interfaces/Toggle";
import styles from "./styles.module.scss";

interface Props {
  id: string;
  startIndex: number;
  toggles: IToggle[];
}

export function ToggleRowMolecule(props: Props) {
  const toggleComponent = (toggle: IToggle):JSX.Element => {
    let className = styles.toggle;

    if(!toggle.label) {
      const styleCenter = styles[`toggle--center`];
      className += ` ${styleCenter}`;
    }

    if(toggle.checked) {
      const styleChecked = styles[`toggle--checked`];
      className += ` ${styleChecked}`;
    }

    return (
      <div key={toggle.id} className={className} onClick={toggle.onClick}>
        <IconAtom icon={toggle.checked ? toggle.iconChecked ?? toggle.icon : toggle.icon} size={toggle.label ? "small" : "large"} alt={toggle.icon} />
        {
          toggle.label && <LabelAtom id={props.id} children={toggle.label} type={toggle.checked ? "white" : "form"} underlined />
        }
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {props.toggles.map((toggleItem:IToggle) => {return toggleComponent(toggleItem)})}
      </div>
    </div>
  );
}

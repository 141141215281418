import { createAsyncThunk, createSlice, GetThunkAPI, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ApiFactory } from "../../api";
import { IDocumentApi } from "../../api/document";
import { IMetadataApi } from "../../api/metadata";
import { MetadataFactory } from "../../factories";
import { Identifier, Metadata } from "../../models";
import { IdentifierMetAfkorting } from "../../models/identifier";
import { Bestand, Documenthandelingen, Documentrelaties, PUBLICATIE_STATUS } from "../../models/metadata";
import { PUBLICATIE_STATUS_SEARCH, SearchResult } from "../../models/search-result";
import { stringToDate } from "../../utils/DateFormatter";
import { ReducerErrorToaster } from "../../utils/ReducerUtils";
import { BIJLAGE_VAN, isBijlageDocument } from "../../utils/Relaties";
import { StringSanitizer } from "../../utils/StringSanitizer";
import { setToast } from "./toast";

export const conceptDate = new Date("9999-12-31T12:00:00.000Z");

const mdFactory = new MetadataFactory();
const stringSanitizer = new StringSanitizer();

interface DocumentError {
  index: number;
  message: string | undefined;
}

interface WijzigenFile {
  pid: string;
  reasonId: string;
}

interface MetadataState {
  data: FormState[];
  reasons: {
    pid: string;
    reasonId: string;
  }[];
  formIndex: number;
  fetching: boolean;
  mode: "create" | "update";
  formUniqueKey: number;
  documentError: {
    pid?: string[];
    indexList?: number[];
  };
  errorReviewed: boolean;
}

export interface FormState {
  file?: File;
  hasChangedMeta?: boolean;
  hasChangedFile?: boolean;
  meta: Metadata;
}

const initialState: MetadataState = {
  data: [],
  reasons: [],
  fetching: false,
  mode: "create",
  formIndex: 0,
  formUniqueKey: 0,
  documentError: {},
  errorReviewed: false,
};

const generateMetadata = (title: string, organization: IdentifierMetAfkorting) => {
  return mdFactory.create(
    {
      id: organization.id,
      label: organization.label,
    },
    [],
    {
      id: "http://publications.europa.eu/resource/authority/language/NLD",
      label: "Nederlands",
    },
    {
      officieleTitel: title,
    },
    {
      documentsoorten: [],
      informatiecategorieen: [],
      themas: [],
    },
    [
      {
        soortHandeling: {
          id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
          label: "vaststelling",
        },
        atTime: new Date().toJSON(),
        wasAssociatedWith: {
          id: organization.id,
          label: organization.label,
        },
      },
    ],
    ["rijksoverheid.nl"],
    undefined,
    {
      id: organization.id,
      label: organization.label,
    }
  );
};

const insertOrRemoveIdentifier = (stateValues: Identifier[] | undefined, identifier: Identifier) => {
  const currentValues = [...(stateValues ?? [])];
  if (currentValues.some((cv: Identifier) => cv.id === identifier.id))
    return currentValues.filter((cv: Identifier) => cv.id !== identifier.id);
  else return [...currentValues, { id: identifier.id, label: identifier.label }];
};

const validateForm = (data: FormState, validateFile: boolean): string | undefined => {
  if (validateFile && !data.file) return "Bestand ontbreekt.";

  if (validatePublisherFields(data)) {
    return "Publicerende organisatie is niet geldig.";
  }

  if (!validateRequiredFields(data)) {
    return "Verplichte velden niet ingevuld.";
  }

  if (!validateFormTextInputs(data)) return "Minimaal 3 karakters vereist.";
};

const validateRequiredFields = (data: FormState): boolean => {
  let requiredValues = [
    data.meta.document.publisher.id,
    data.meta.document.titelcollectie.officieleTitel,
    data.meta.document.classificatiecollectie.documentsoorten,
    data.meta.document.identifiers[0],
    data.meta.document.language.id,
  ];

  if (process.env.REACT_APP_INFORMATIECATEGORIE === "true")
    requiredValues.push(data.meta.document.classificatiecollectie.informatiecategorieen);

  return requiredValues.every((rv: string | Identifier[]) => rv && rv.length > 0);
};

const validatePublisherFields = (data: FormState): boolean => {
  return data.meta.document.publisher.label!.includes("(verlopen)");
};

const validateFormTextInputs = (data: FormState): boolean => {
  const {
    titelcollectie: { officieleTitel, verkorteTitels, alternatieveTitels },
    naamOpsteller,
    aggregatiekenmerk,
    onderwerpen,
    identifiers,
    omschrijvingen,
    creatiedatum,
  } = data.meta.document;

  let textValuesRequired = [identifiers, officieleTitel];

  let textValuesOptional = [
    verkorteTitels,
    alternatieveTitels,
    aggregatiekenmerk,
    onderwerpen,
    omschrijvingen,
    creatiedatum,
    naamOpsteller,
  ];

  const textValuesRequiredIncorrect = textValuesRequired.filter(
    (tekst: string | string[] | undefined) =>
      tekst !== undefined &&
      tekst.length > 0 &&
      !(Array.isArray(tekst) ? tekst.every((arrayValue) => arrayValue.length >= 3) : tekst.length >= 3)
  );
  const textValuesOptionalIncorrect = textValuesOptional.filter(
    (tekst: string | string[] | undefined) =>
      tekst !== undefined &&
      !(Array.isArray(tekst)
        ? tekst.every((arrayValue) => arrayValue.length >= 3 || arrayValue.length === 0)
        : tekst.length >= 3 || tekst.length === 0)
  );
  return textValuesRequiredIncorrect.length === 0 && textValuesOptionalIncorrect.length === 0;
};

const removeEmpty = (object: any): any => {
  if (object === "" || object === null) return undefined;

  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) {
      if (object[i] === "" || object[i] === null) object[i] = undefined;
    }
    object = object.filter((value: any) => {
      return value !== undefined;
    });

    if (!object.length) object = undefined;
  }

  return object;
};

const UpdateErrorsSetFormIndex = (thunkAPI: any, errorsArray: number[]) => {
  thunkAPI.dispatch(updateErrors(errorsArray));
  thunkAPI.dispatch(changeFormIndex(errorsArray[0]));
};

const getBijlagenRelatiesEnSetForm = async (
  thunkAPI: GetThunkAPI<any>,
  api: IMetadataApi,
  meta: Metadata
): Promise<Documentrelaties[]> => {
  const isHoofdDoc = !isBijlageDocument(meta.documentrelaties ?? []);

  if (!isHoofdDoc) {
    const rel_pid = stringSanitizer.extractIdentifierFromPid((meta.documentrelaties as Documentrelaties[])[0].relation);
    if (rel_pid) {
      const hoofdDoc = await api.fetchMetadata(rel_pid).catch((error: any) => {
        ReducerErrorToaster(thunkAPI, error);
        throw error;
      });
      thunkAPI.dispatch(_addForm({ meta: hoofdDoc }));
      return hoofdDoc.documentrelaties ?? [];
    }
  } else {
    thunkAPI.dispatch(_addForm({ meta }));
    return meta.documentrelaties ?? [];
  }
  return [];
};

const informatiecategorieenToggleCheck = (state: MetadataState) => {
  if (process.env.REACT_APP_INFORMATIECATEGORIE === "false") {
    let nieuweState: any = { ...state };
    const modifiedItems = nieuweState.data.map((dataItem: any) => {
      let nieuwMetaItem = JSON.parse(JSON.stringify(dataItem.meta));
      delete nieuwMetaItem.document.classificatiecollectie.informatiecategorieen;
      return { ...dataItem, meta: nieuwMetaItem };
    });
    return { ...state, data: modifiedItems };
  } else {
    return state;
  }
};

const checkErrors = (
  data: FormState[],
  createNew: boolean
): {
  index: number;
  message: string | undefined;
}[] => {
  return data
    .map((formState: FormState, index: number) => {
      if (formState.meta.document.pid === undefined && !createNew) return { index: index, message: undefined };
      if (formState.meta.document.pid !== undefined) return { index: index, message: validateForm(formState, false) };
      return { index: index, message: validateForm(formState, true) };
    })
    .filter((error: DocumentError) => error?.message !== undefined);
};

const updateError = (thunkAPI: any, errorList: { index: number; message: string | undefined }[]) => {
  if (errorList.length > 0) {
    const error = errorList[0];
    thunkAPI.dispatch(changeFormIndex(error.index));
    const errorMessage = new Error(error.message);
    ReducerErrorToaster(thunkAPI, errorMessage);
  }
};

const updateMetadata = async (
  thunkAPI: any,
  metadata_api: IMetadataApi,
  _form: FormState,
  errorIndexList: number[],
  pid: string,
  i: number
) => {
  if (_form.hasChangedMeta) {
    const resultMeta = await metadata_api.updateMetadata(_form.meta, pid).catch((error: any) => {
      errorIndexList.push(i);
      ReducerErrorToaster(thunkAPI, error);
      thunkAPI.dispatch(changeFormIndex(i));
    });
    if (resultMeta) {
      thunkAPI.dispatch(updateBewerktijd(i));
    }
  }
};

const updateFiles = async (
  thunkAPI: any,
  document_api: IDocumentApi,
  _form: FormState,
  errorIndexList: number[],
  pid: string,
  reasonId: string | undefined,
  i: number
) => {
  if (_form.file && _form.hasChangedFile) {
    await document_api
      .updateDocument(`${process.env.REACT_APP_DOCUMENT_API_ENDPOINT}/documenten/${pid}`, _form.file, reasonId ?? "")
      .catch((error: any) => {
        errorIndexList.push(i);
        ReducerErrorToaster(thunkAPI, error);
        thunkAPI.dispatch(changeFormIndex(i));
      });

    thunkAPI.dispatch(removeFile(i));
  }
};

const updateErrorsOfSuccesvol = (
  thunkAPI: any,
  errorIndexList: number[],
  errorList: { index: number; message: string | undefined }[]
) => {
  if (errorIndexList.length > 0) {
    UpdateErrorsSetFormIndex(thunkAPI, errorIndexList);
    throw Error(errorList[0].message);
  } else {
    thunkAPI.dispatch(clearReasons());

    thunkAPI.dispatch(
      setToast({
        autoClose: true,
        type: "success",
        message: {
          message: "Document(en) aangeleverd.",
        },
      })
    );
  }
};

export const create = createAsyncThunk<unknown, undefined, { state: RootState }>(
  "metadata/create",
  async (_, thunkAPI) => {
    const metadata_api = ApiFactory.createMetadataApi();
    const document_api = ApiFactory.createDocumentApi();

    let state: MetadataState = thunkAPI.getState().meta;
    const hoofd_document: FormState = state.data[0];
    let errorIndexList: number[] = [];
    let createResult: { uploadUrl: string; pid: string };

    state = informatiecategorieenToggleCheck(state);

    const errorList = state.data
      .map((formState: FormState, index: number) => {
        return { index: index, message: validateForm(formState, true) };
      })
      .filter((error: DocumentError) => error?.message !== undefined);

    errorIndexList = errorList.map((error: DocumentError) => error.index);
    if (errorList.length > 0) {
      const error = errorList[0];
      thunkAPI.dispatch(changeFormIndex(error.index));
    }

    const hoofdDocumentError = errorList?.find((error: DocumentError) => error.index === 0);
    if (hoofdDocumentError) {
      const error = new Error("Vul correct de metadata in van het hoofddocument.");
      UpdateErrorsSetFormIndex(thunkAPI, [0]);
      ReducerErrorToaster(thunkAPI, error);
      throw error;
    }
    createResult = await metadata_api.createMetadataSession(hoofd_document.meta).catch((error: any) => {
      UpdateErrorsSetFormIndex(thunkAPI, [0]);
      ReducerErrorToaster(thunkAPI, error);
      throw error;
    });

    await document_api.uploadDocument(createResult.uploadUrl, hoofd_document.file as File).catch((error: any) => {
      UpdateErrorsSetFormIndex(thunkAPI, [0]);
      ReducerErrorToaster(thunkAPI, error);
      throw error;
    });

    thunkAPI.dispatch(updateErrorPid(createResult.pid));
    thunkAPI.dispatch(updatePid({ pid: createResult.pid, index: 0 }));
    thunkAPI.dispatch(updateErrorReviewed(false));
    thunkAPI.dispatch(_updateHasChanged({ index: 0, hasChanged: false }));

    for (let i = 1; i < state.data.length; i++) {
      let bijlage = state.data[i];

      if (errorList?.find((error: DocumentError) => error.index === i)) {
        thunkAPI.dispatch(_updateBijlageRelatie({ index: i, pid: createResult!.pid }));
        thunkAPI.dispatch(_updateHasChanged({ index: i, hasChanged: true }));
        continue;
      }

      const _bijlage: FormState = {
        ...bijlage,
        meta: {
          ...bijlage.meta,
          documentrelaties: [
            {
              relation: stringSanitizer.extractIdentifierFromPid(createResult!.pid)!,
              role: BIJLAGE_VAN,
            },
          ],
        },
      };

      const createBijlageResult = await metadata_api.createMetadataSession(_bijlage.meta).catch((error: any) => {
        errorIndexList.push(i);
        thunkAPI.dispatch(_updateBijlageRelatie({ index: i, pid: createResult!.pid }));
        thunkAPI.dispatch(_updateHasChanged({ index: i, hasChanged: true }));
        ReducerErrorToaster(thunkAPI, error);
        throw error;
      });

      await document_api.uploadDocument(createBijlageResult.uploadUrl, _bijlage.file as File).catch((error: any) => {
        errorIndexList.push(i);
        thunkAPI.dispatch(_updateBijlageRelatie({ index: i, pid: createResult!.pid }));
        thunkAPI.dispatch(_updateHasChanged({ index: i, hasChanged: true }));
        ReducerErrorToaster(thunkAPI, error);
        throw error;
      });

      thunkAPI.dispatch(updatePid({ pid: createBijlageResult.pid, index: i }));
      thunkAPI.dispatch(_updateHasChanged({ index: i, hasChanged: false }));
    }

    if (errorIndexList.length > 0) {
      UpdateErrorsSetFormIndex(thunkAPI, errorIndexList);
      const pid = stringSanitizer.extractIdentifierFromPid(createResult!.pid);
      const newURL = new URL(window.location.href + "/" + pid);
      window.location.href = newURL.toString();
      throw Error(errorList[0].message);
    } else {
      thunkAPI.dispatch(
        setToast({
          autoClose: true,
          type: "success",
          message: {
            message: "Document(en) aangeleverd.",
          },
        })
      );

      thunkAPI.dispatch(clear());
      thunkAPI.dispatch(addForm());
    }
  }
);

export const update = createAsyncThunk<unknown, boolean, { state: RootState }>(
  "metadata/update",
  async (createNew: boolean, thunkAPI) => {
    const metadata_api = ApiFactory.createMetadataApi();
    const document_api = ApiFactory.createDocumentApi();
    let state: MetadataState = (thunkAPI.getState() as any).meta;
    let errorIndexList: number[] = [];

    thunkAPI.dispatch(updateErrors(errorIndexList));
    state = informatiecategorieenToggleCheck(state);
    const errorList = checkErrors(state.data, createNew);
    errorIndexList = errorList.map((error: DocumentError) => error.index);

    updateError(thunkAPI, errorList);

    for (let i = state.data.length - 1; i >= 0; i--) {
      if (errorList?.find((error: DocumentError) => error.index === i)) continue;

      const _form = state.data[i];
      const isNew = _form.meta.document.pid === undefined;
      if (isNew && createNew) {
        const createResult = await metadata_api.createMetadataSession(_form.meta).catch((error: any) => {
          errorIndexList.push(i);
          ReducerErrorToaster(thunkAPI, error);
          throw error;
        });

        await document_api.uploadDocument(createResult.uploadUrl, _form.file as File).catch((error: any) => {
          errorIndexList.push(i);
          ReducerErrorToaster(thunkAPI, error);
        });

        thunkAPI.dispatch(updateNewBijlage({ index: i, pid: createResult.pid }));
        state = (thunkAPI.getState() as any).meta;
      } else if (!isNew) {
        const pid = stringSanitizer.extractIdentifierFromPid(_form.meta.document.pid);
        const reasonId = state.reasons.find((wijzig: WijzigenFile) => wijzig.pid === _form.meta.document.pid)?.reasonId;

        updateMetadata(thunkAPI, metadata_api, _form, errorIndexList, pid, i);

        updateFiles(thunkAPI, document_api, _form, errorIndexList, pid, reasonId, i);
      }
    }

    updateErrorsOfSuccesvol(thunkAPI, errorIndexList, errorList);
  }
);

export const publiceerConcept = createAsyncThunk(
  "metadata/publiceerConcept",
  async (selectedConcepts: SearchResult[], thunkAPI) => {
    const metadata_api = ApiFactory.createMetadataApi();

    for (let i = selectedConcepts.length - 1; i >= 0; i--) {
      const _form = selectedConcepts[i];
      const pid = stringSanitizer.extractIdentifierFromPid(_form.document.pid);

      const _newForm: SearchResult = {
        ..._form,
        document: {
          ..._form.document,
          zichtbaarheidsdatumtijd: undefined,
        },
      };

      await metadata_api.updateMetadata(_newForm, pid).catch((error: any) => {
        ReducerErrorToaster(thunkAPI, error);
        thunkAPI.dispatch(changeFormIndex(i));
      });
    }

    thunkAPI.dispatch(
      setToast({
        autoClose: true,
        type: "success",
        message: {
          message: "Document(en) aangeleverd.",
        },
      })
    );
  }
);

export const fetchForm = createAsyncThunk("metadata/get", async (id: string, thunkAPI) => {
  if (!id) {
    const error = new Error("Id niet gevonden.");
    ReducerErrorToaster(thunkAPI, error);
    throw error;
  }

  const api = ApiFactory.createMetadataApi();
  const meta = await api.fetchMetadata(id).catch((error: any) => {
    ReducerErrorToaster(thunkAPI, error);
    throw error;
  });

  thunkAPI.dispatch(clear());
  let bijlage_relaties: Documentrelaties[] = await getBijlagenRelatiesEnSetForm(thunkAPI, api, meta);

  // Haal relaties op
  for (let i = 0; i < bijlage_relaties.length; i++) {
    const rel_pid = stringSanitizer.extractIdentifierFromPid(bijlage_relaties[i].relation);
    if (rel_pid) {
      const bijlage = await api.fetchMetadata(rel_pid).catch((error: any) => {
        ReducerErrorToaster(thunkAPI, error);
        throw error;
      });
      thunkAPI.dispatch(_addForm({ meta: bijlage }));
    }
  }

  thunkAPI.dispatch(setIndexByPid(id));
});

export const unpublish = createAsyncThunk(
  "metadata/delete",
  async (
    args: {
      id: string;
      reden: string;
    },
    thunkAPI
  ) => {
    const api = ApiFactory.createDocumentApi();
    if (!args.id) {
      const error = new Error("Id niet gevonden.");
      ReducerErrorToaster(thunkAPI, error);
      throw error;
    }
    if (!args.reden) {
      const error = new Error("Geen reden van wijzigen opgegeven.");
      ReducerErrorToaster(thunkAPI, error);
      throw error;
    }

    const pid = args.id.split("/").pop() ?? "";
    await api.depubliceerSession(pid, args.reden).catch((error: any) => {
      ReducerErrorToaster(thunkAPI, error);
      throw error;
    });

    thunkAPI.dispatch(
      setToast({
        autoClose: true,
        type: "success",
        message: {
          message: "Document is gedepubliceerd",
        },
      })
    );

    return args.id;
  }
);

export const addForm = createAsyncThunk("metadata/addForm", (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const formState: MetadataState = state.meta;
  const org = state.auth.organisatie[0];
  const bijlage = generateMetadata("", org);

  // if edit mode
  if (formState.data.length > 0) {
    // Haal hoofdock op
    const { meta } = formState.data[0];

    // Kopieer velden
    bijlage.document.publisher = meta.document.publisher;

    // Voeg relatie naar het hoofddocument in bijlage
    if (formState.mode === "update")
      bijlage.documentrelaties = [
        {
          role: BIJLAGE_VAN,
          relation: stringSanitizer.extractIdentifierFromPid(meta.document.pid),
        },
      ];
  }

  thunkAPI.dispatch(
    _addForm({
      meta: bijlage,
    })
  );
});

export const removeForm = createAsyncThunk("metadata/removeForm", (index: number, thunkAPI) => {
  thunkAPI.dispatch(_removeForm(index));
});

const isNotIngetrokken = (element: Metadata | SearchResult) => {
  return (
    element.plooiIntern?.publicatiestatus === PUBLICATIE_STATUS_SEARCH.ingetrokken ||
    element.plooiIntern?.publicatiestatus === PUBLICATIE_STATUS.ingetrokken
  );
};

const metadataState = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<"update" | "create">) => {
      state.mode = action.payload;
    },

    updateNewBijlage: (state, action: PayloadAction<{ index: number; pid: string }>) => {
      state.data[action.payload.index].meta.document.pid = action.payload.pid;
      state.data[action.payload.index].file = undefined;

      const now = new Date();
      const nowString = `${new Date().toLocaleDateString("NL-nl")} ${now.toLocaleTimeString()}`;

      state.data[action.payload.index].meta.document.creatiedatum = nowString;
    },

    _updateBijlageRelatie: (state, action: PayloadAction<{ index: number; pid: string }>) => {
      state.data[action.payload.index].meta.documentrelaties = [
        {
          relation: stringSanitizer.extractIdentifierFromPid(action.payload.pid),
          role: BIJLAGE_VAN,
        },
      ];
    },

    _updateHasChanged: (state, action: PayloadAction<{ index: number; hasChanged: boolean }>) => {
      state.data[action.payload.index].hasChangedMeta = action.payload.hasChanged;
      state.data[action.payload.index].hasChangedFile = action.payload.hasChanged;
    },

    updateBewerktijd: (state, action: PayloadAction<number>) => {
      if (state.data[action.payload].meta.versies?.length) {
        state.data[action.payload].meta.versies![0].mutatiedatumtijd = new Date(Date.now()).toJSON();
      } else {
        state.data[action.payload].meta.versies = [
          {
            mutatiedatumtijd: new Date(Date.now()).toJSON(),
          },
        ];
      }
    },

    _addForm: (state, action: PayloadAction<FormState>) => {
      state.data.push(action.payload);
      state.formIndex = state.data.length - 1;
      state.formUniqueKey += 1;

      if (state.formIndex > 0) {
        const hoofdDoc = state.data[0];
        const bijlage = state.data[state.formIndex];

        if (bijlage.meta.document.pid) return;

        const diff =
          hoofdDoc.meta.document.zichtbaarheidsdatumtijd &&
          new Date().getTime() - new Date(Date.parse(hoofdDoc.meta.document.zichtbaarheidsdatumtijd)).getTime();
        if (hoofdDoc.meta.plooiIntern?.publicatiestatus === PUBLICATIE_STATUS.uitgesteld || (diff && diff < 0))
          bijlage.meta.document.zichtbaarheidsdatumtijd = hoofdDoc.meta.document.zichtbaarheidsdatumtijd;

        bijlage.meta.document.classificatiecollectie.onderwerpenRonl =
          hoofdDoc.meta.document.classificatiecollectie.onderwerpenRonl;
        bijlage.meta.document.classificatiecollectie.themas = hoofdDoc.meta.document.classificatiecollectie.themas;
        bijlage.meta.document.medeverantwoordelijken = hoofdDoc.meta.document.medeverantwoordelijken;
      }
    },

    _removeForm: (state, action: PayloadAction<number>) => {
      state.documentError.indexList = state.documentError.indexList?.filter(
        (errorIndex: number) => errorIndex !== action.payload
      );
      state.data.splice(action.payload, 1);
      if (state.data.length <= state.formIndex) state.formIndex = state.data.length - 1;
    },

    clear: (state) => {
      state.formUniqueKey += 1;
      state.reasons = [];
      state.data = [];
      state.formIndex = 0;
      state.documentError = {};
    },

    changeFormIndex: (state, action: PayloadAction<number>) => {
      state.formIndex = action.payload;
      state.formUniqueKey += 1;
    },

    addReason: (state, action: PayloadAction<{ pid: string; reasonId: string }>) => {
      const { pid, reasonId } = action.payload;
      state.reasons.push({ pid, reasonId });
    },

    removeFile: (state, action: PayloadAction<number>) => {
      state.data[action.payload].file = undefined;
    },

    clearReasons: (state) => {
      state.reasons = [];
    },

    setIndexByPid: (state, action: PayloadAction<string>) => {
      const index = state.data.findIndex(
        (formState: FormState) => formState.meta.document.pid!.indexOf(action.payload) > -1
      );
      if (index !== -1) state.formIndex = index;
      else state.formIndex = 0;
    },

    updateGeldigheidEinddatum: (state, action: PayloadAction<Date | undefined>) => {
      if (!state.data[state.formIndex].meta.document.geldigheid)
        state.data[state.formIndex].meta.document.geldigheid = {};

      state.data[state.formIndex].meta.document.geldigheid!.einddatum = action.payload
        ? action.payload.toJSON()
        : undefined;

      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateGeldigheidBegindatum: (state, action: PayloadAction<Date | undefined>) => {
      if (!state.data[state.formIndex].meta.document.geldigheid)
        state.data[state.formIndex].meta.document.geldigheid = {};

      state.data[state.formIndex].meta.document.geldigheid!.begindatum = action.payload
        ? action.payload.toJSON()
        : undefined;

      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateDocumentSoorten: (state, action: PayloadAction<Identifier>) => {
      state.data[state.formIndex].meta.document.classificatiecollectie.documentsoorten = [action.payload];
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateDocumentHandelingOrganisatie: (state, action: PayloadAction<{ identifier: Identifier; index: number }>) => {
      state.data[state.formIndex].meta.document.documenthandelingen[action.payload.index].wasAssociatedWith = {
        id: action.payload.identifier.id,
      };
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateDocumentHandelingSoort: (state, action: PayloadAction<{ identifier: Identifier; index: number }>) => {
      state.data[state.formIndex].meta.document.documenthandelingen[action.payload.index].soortHandeling =
        action.payload.identifier;
      state.data[state.formIndex].hasChangedMeta = true;
    },

    removeDocumentHandelingSoort: (state, action: PayloadAction<number>) => {
      state.data[state.formIndex].meta.document.documenthandelingen.splice(action.payload, 1);
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateDocumentHandelingDate: (state, action: PayloadAction<{ date: string; index: number }>) => {
      const datevalue = new Date(action.payload.date).toJSON();
      state.data[state.formIndex].meta.document.documenthandelingen[action.payload.index].atTime = datevalue;
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateVerantwoordelijke: (state, action: PayloadAction<Identifier>) => {
      if (state.data[state.formIndex].meta.document.verantwoordelijke?.id !== action.payload.id)
        state.data[state.formIndex].meta.document.verantwoordelijke = action.payload;
      else state.data[state.formIndex].meta.document.verantwoordelijke = undefined;

      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateMedeverantwoordelijken: (state, action: PayloadAction<Identifier>) => {
      state.data[state.formIndex].meta.document.medeverantwoordelijken = insertOrRemoveIdentifier(
        state.data[state.formIndex].meta.document.medeverantwoordelijken,
        action.payload
      );
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateOmschrijving: (state, action: PayloadAction<string>) => {
      state.data[state.formIndex].meta.document.omschrijvingen = removeEmpty([action.payload]);
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateFile: (state, action: PayloadAction<File | undefined>) => {
      const activeForm = state.data[state.formIndex];
      activeForm.file = action.payload;
      activeForm.hasChangedFile = true;

      if (action.payload && activeForm.meta.document.titelcollectie.officieleTitel.length === 0) {
        activeForm.meta.document.titelcollectie.officieleTitel = stringSanitizer.removeExtension(action.payload.name);
        activeForm.hasChangedMeta = true;
      }

      let versiesFile =
        activeForm.meta.versies?.length! > 0 &&
        activeForm.meta.versies![0].bestanden!.find(
          (bestand: Bestand) => bestand.label === "file" || bestand.label === "pdf" || bestand.label === "zip"
        );
      if (versiesFile !== undefined && versiesFile !== false && action.payload)
        versiesFile.bestandsnaam = action.payload?.name;
    },

    updateThemas: (state, action: PayloadAction<Identifier>) => {
      state.data[state.formIndex].meta.document.classificatiecollectie.themas = insertOrRemoveIdentifier(
        state.data[state.formIndex].meta.document.classificatiecollectie.themas,
        action.payload
      );
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateOnderwerpenRonl: (state, action: PayloadAction<Identifier>) => {
      state.data[state.formIndex].meta.document.classificatiecollectie.onderwerpenRonl = insertOrRemoveIdentifier(
        state.data[state.formIndex].meta.document.classificatiecollectie.onderwerpenRonl,
        action.payload
      );
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateDocumentHandelingen: (state, action: PayloadAction<Documenthandelingen[]>) => {
      state.data[state.formIndex].meta.document.documenthandelingen = action.payload;
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateIdentifiers: (state, action: PayloadAction<string[]>) => {
      state.data[state.formIndex].meta.document.identifiers = action.payload;
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateOnderwerpen: (state, action: PayloadAction<string[]>) => {
      state.data[state.formIndex].meta.document.onderwerpen = removeEmpty(action.payload);
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateVerkorteTitel: (state, action: PayloadAction<string>) => {
      state.data[state.formIndex].meta.document.titelcollectie.verkorteTitels = removeEmpty([action.payload]);
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateAltTitel: (state, action: PayloadAction<string>) => {
      state.data[state.formIndex].meta.document.titelcollectie.alternatieveTitels = removeEmpty([action.payload]);
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateTitel: (state, action: PayloadAction<string>) => {
      state.data[state.formIndex].meta.document.titelcollectie.officieleTitel = action.payload;
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updatePublisher: (state, action: PayloadAction<Identifier>) => {
      for (let i = 0; i < state.data.length; i++) {
        if (
          state.data[i].meta.document.verantwoordelijke &&
          state.data[i].meta.document.publisher &&
          state.data[i].meta.document.verantwoordelijke!.id === state.data[i].meta.document.publisher!.id
        ) {
          state.data[i].meta.document.verantwoordelijke = action.payload;
        }

        state.data[i].meta.document.publisher = action.payload;
        state.data[i].hasChangedMeta = true;
      }
    },

    updateOpsteller: (state, action: PayloadAction<string>) => {
      state.data[state.formIndex].meta.document.naamOpsteller = removeEmpty(action.payload);
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateAggegratieKenmer: (state, action: PayloadAction<string>) => {
      state.data[state.formIndex].meta.document.aggregatiekenmerk = removeEmpty(action.payload);
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateCreatieDatum: (state, action: PayloadAction<string>) => {
      state.data[state.formIndex].meta.document.creatiedatum = removeEmpty(action.payload);
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateTaal: (state, action: PayloadAction<Identifier>) => {
      state.data[state.formIndex].meta.document.language = action.payload;
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateInformatiecategrieen: (state, action: PayloadAction<Identifier>) => {
      state.data[state.formIndex].meta.document.classificatiecollectie.informatiecategorieen = [action.payload];
      state.data[state.formIndex].hasChangedMeta = true;
    },

    updateZichtbaarheidDate: (state, action: PayloadAction<string | undefined>) => {
      if (!action.payload || action.payload === undefined) {
        state.data.forEach((element: FormState) => {
          if (!isNotIngetrokken(element.meta)) {
            element.meta.document.zichtbaarheidsdatumtijd = action.payload;
            element.hasChangedMeta = true;
          }
        });

        return;
      }

      let newDate = stringToDate(action.payload!) ?? new Date();
      const zdt = state.data[state.formIndex].meta.document.zichtbaarheidsdatumtijd;
      if (zdt) {
        const date = new Date(zdt);
        newDate.setHours(date.getHours());
        newDate.setMinutes(date.getMinutes());
      }

      if (newDate < new Date()) {
        newDate = new Date();
        newDate.setMinutes(newDate.getMinutes() + 15);
      }

      state.data.forEach((element: FormState) => {
        if (!isNotIngetrokken(element.meta)) {
          element.meta.document.zichtbaarheidsdatumtijd = newDate.toJSON();
          element.hasChangedMeta = true;
        }
      });
    },

    updateZichtbaarheidTime: (state, action: PayloadAction<string>) => {
      let newDate = new Date();

      const zdt = state.data[state.formIndex].meta.document.zichtbaarheidsdatumtijd;
      if (zdt) {
        const date = new Date(zdt);
        newDate.setFullYear(date.getFullYear());
        newDate.setMonth(date.getMonth());
        newDate.setDate(date.getDate());
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
      }

      const s = action.payload!.split(":");
      newDate.setHours(Number(s[0]));
      newDate.setMinutes(Number(s[1]));
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);

      if (newDate < new Date()) return;

      state.data.forEach((element) => {
        if (!isNotIngetrokken(element.meta)) {
          element.meta.document.zichtbaarheidsdatumtijd = newDate.toJSON();
          element.hasChangedMeta = true;
        }
      });
    },

    updateErrors: (state, action: PayloadAction<number[] | undefined>) => {
      if (action.payload) state.documentError.indexList = action.payload;
      else state.documentError.indexList = [];
    },

    updateErrorPid: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        if (!state.documentError.pid) state.documentError.pid = [];

        state.documentError.pid?.push(stringSanitizer.extractIdentifierFromPid(action.payload));
      } else {
        state.documentError.pid = [];
      }
    },

    updateErrorReviewed: (state, action: PayloadAction<boolean>) => {
      state.errorReviewed = action.payload;
    },

    updatePid: (state, action: PayloadAction<{ pid: string; index: number }>) => {
      state.data[action.payload.index].meta.document.pid = action.payload.pid;
    },
  },
  extraReducers: (caseBuilder) => {
    caseBuilder.addCase(create.pending, (state, action) => {
      state.fetching = true;
    });
    caseBuilder.addCase(create.rejected, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(create.fulfilled, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(fetchForm.pending, (state, action) => {
      state.fetching = true;
    });
    caseBuilder.addCase(fetchForm.rejected, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(fetchForm.fulfilled, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(update.pending, (state, action) => {
      state.fetching = true;
    });
    caseBuilder.addCase(update.rejected, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(update.fulfilled, (state, action) => {
      state.fetching = false;
      state.data.forEach((formState: FormState) => {
        formState.hasChangedFile = false;
        formState.hasChangedMeta = false;
      });

      if (state.data[state.formIndex].meta.versies)
        state.data[state.formIndex].meta.versies![0].mutatiedatumtijd = new Date().toJSON();
    });
    caseBuilder.addCase(publiceerConcept.pending, (state, action) => {
      state.fetching = true;
    });
    caseBuilder.addCase(publiceerConcept.rejected, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(publiceerConcept.fulfilled, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(unpublish.pending, (state, action) => {
      state.fetching = true;
    });
    caseBuilder.addCase(unpublish.rejected, (state, action) => {
      state.fetching = false;
    });
    caseBuilder.addCase(unpublish.fulfilled, (state, action: PayloadAction<string>) => {
      state.fetching = false;

      const index = state.data.findIndex((formState: FormState) => formState.meta.document.pid === action.payload);
      const isHoofdDoc = index === 0;

      if (isHoofdDoc)
        state.data.forEach(
          (formState: FormState) =>
            (formState.meta.plooiIntern = {
              publicatiestatus: PUBLICATIE_STATUS.ingetrokken,
            })
        );
      else {
        state.data[index].meta.plooiIntern = {
          publicatiestatus: PUBLICATIE_STATUS.ingetrokken,
        };
      }
    });
  },
});

const {
  _addForm,
  _removeForm,
  updateNewBijlage,
  removeFile,
  updateBewerktijd,
  _updateBijlageRelatie,
  _updateHasChanged,
} = metadataState.actions;
export const {
  updateAggegratieKenmer,
  removeDocumentHandelingSoort,
  updateGeldigheidBegindatum,
  updateInformatiecategrieen,
  updateTaal,
  updateCreatieDatum,
  updatePublisher,
  updateOpsteller,
  updateTitel,
  updateAltTitel,
  updateVerkorteTitel,
  updateOnderwerpen,
  updateIdentifiers,
  updateDocumentHandelingen,
  updateOnderwerpenRonl,
  updateThemas,
  updateFile,
  updateOmschrijving,
  updateMedeverantwoordelijken,
  updateVerantwoordelijke,
  updateDocumentHandelingDate,
  updateDocumentHandelingSoort,
  updateDocumentHandelingOrganisatie,
  updateErrors,
  updateErrorPid,
  updateErrorReviewed,
  updatePid,
  setMode,
  clear,
  changeFormIndex,
  addReason,
  clearReasons,
  setIndexByPid,
  updateGeldigheidEinddatum,
  updateDocumentSoorten,
  updateZichtbaarheidDate,
  updateZichtbaarheidTime,
} = metadataState.actions;

export default metadataState.reducer;

import styles from "./styles.module.scss";

interface Props {
  text: string;
  onRemove: () => void;
  disabled?: boolean;
}

export function ChipAtom(props: Props) {
  let className = styles.chip;
  if(props.disabled){
    const style = styles[`chip--disabled`];
    className += ` ${style}`;
  } 

  return (
    <div className={className}>
      {props.text}
      <button
        disabled={props.disabled}
        id={"remove_" + props.text}
        className={styles.chip__button}
        onClick={props.onRemove}
        name={"remove_" + props.text}
        type="button" 
      >x</button>
    </div>
  );
}

import { ButtonMolecule, TextInputMolecule } from "..";
import { ContainerAtom } from "../../atoms";
import styles from "./styles.module.scss";


interface Props {
  value: string;
  onSubmit: () => void;
  onChange: (value:string) => void;
}

export function SearchInputMolecule(props: Props) {

  return (
    <div className={styles.container}>
      <ContainerAtom type="grid">
        <TextInputMolecule placeholder="Zoeken..." value={props.value} onEnter={() => props.onSubmit()} onChange={(value: string) => {props.onChange(value)}}/>
        <ButtonMolecule text={"Zoeken"} id={"zoeken"} title={"Zoek in alle metadata en de geïndexeerde inhoud van een document."} onClick={()=> props.onSubmit()} type={"primary"} />
      </ContainerAtom>
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import { Teksten } from "../../../content";
import { Melding } from "../../../models/melding";
import { useAppSelector } from "../../../store/hooks";
import { selectMelding } from "../../../store/selectors";
import { ContainerAtom } from "../../atoms";
import { ButtonMolecule, JumbotronMolecule } from "../../molecules";
import { HulpContact } from "../hulp-contact";
import { Publicatie } from "../publicatie";

export function StartOrganism() {
  const navigate = useNavigate();
  const { meldingen } = useAppSelector(selectMelding);

  const onNavCreate = () => {
    navigate("/aanleveren");
  };

  return (
    <ContainerAtom>
      <JumbotronMolecule
        center
        header={Teksten.login.auth.header}
        link={
          <ButtonMolecule
            text={Teksten.login.auth.text}
            id={Teksten.login.auth.id}
            title={Teksten.login.auth.title}
            onClick={onNavCreate}
            type={"primary"}
          />
        }
        intro={Teksten.login.auth.intro}
      />
      {meldingen &&
        meldingen.map((melding: Melding) => {
          return (
            <JumbotronMolecule
              key={melding.omschrijving}
              center
              header={Teksten.login.storing.header}
              intro={melding.omschrijving}
              type="warning"
            />
          );
        })}
      <ContainerAtom type="row">
        <Publicatie />
        <HulpContact />
      </ContainerAtom>
    </ContainerAtom>
  );
}

import { Links, Teksten } from "../../../content";
import { TekstArray } from "../../../models/teksten";
import { ContainerAtom, IconAtom, LinkAtom, SpacerAtom } from "../../atoms";
import { TypographyAtom } from "../../atoms/typography";
import { JumbotronMolecule } from "../../molecules";

export function Publicatie() {
  const intro = (
    <ContainerAtom type="columns">
      <SpacerAtom space={4} />
      {Teksten.publicatie.alinia.map((tekst:TekstArray, index:number) => {
        return (
          <div key={"publicatie_tekst_" + index}>
            <TypographyAtom>{tekst.tekst}</TypographyAtom>
            <SpacerAtom space={8} />
          </div>
        );
      })}
      <ContainerAtom>
        <LinkAtom {...Links.extern.portaal} newWindow />
        <IconAtom icon={"icon-cta-right"} size={"medium"} alt={"update"} />
      </ContainerAtom>
    </ContainerAtom>
  );

  return <JumbotronMolecule header={Teksten.publicatie.title} intro={intro} />;
}

import { ICategoryList } from "../ui/interfaces/CategoryList";
import { ICheckbox } from "../ui/interfaces/Checkbox";

function selectableListFilter(
  list: ICategoryList,
  search: string
): ICategoryList {
  if (list.title && list.title.toLocaleLowerCase().indexOf(search) > -1)
    return list;

  list.options = list.options.filter((option: ICheckbox) => {
    return (
      option.label !== undefined &&
      option.label.toLocaleLowerCase().indexOf(search) > -1
    );
  });

  if (list.sublist && list.sublist.length > 0) {
    for (let j = list.sublist.length - 1; j >= 0; j--) {
      selectableListFilter(list.sublist[j], search);
    }

    list.sublist = list.sublist.filter(
      (categoryList: ICategoryList) => categoryList.options.length > 0
    );
  }

  return list;
}

export function filterBySearch(
  lists: ICategoryList[],
  searchTerm: string
): ICategoryList[] {
  if (!searchTerm || searchTerm.length === 0) return lists;

  const clone = JSON.parse(JSON.stringify(lists));
  const lowerSearch = searchTerm.toLowerCase();
  const filteredLists = clone.map((list: ICategoryList) => {
    return selectableListFilter(list, lowerSearch);
  });

  if (
    filteredLists.filter(
      (list: ICategoryList) => list.sublist && list.sublist.length > 0
    ).length > 0
  )
    return filteredLists.filter(
      (list: ICategoryList) => list.sublist && list.sublist.length > 0
    );

  return filteredLists.filter((list: ICategoryList) => list.options.length > 0);
}

export function getSublistsOptions(item: ICategoryList): ICategoryList[] {
  const stack = [item];
  const elements = [];

  while (stack.length > 0) {
    const currentItem = stack.pop();

    if (currentItem) {
      const options = currentItem.options.filter(
        (checkbox: ICheckbox) =>
          !currentItem.sublist?.some(
            (list: ICategoryList) => checkbox.label === list.title
          )
      );
      const sublist = currentItem.sublist?.filter(
        (list: ICategoryList) => list.sublist
      );

      elements.push({
        title: currentItem.title,
        options: options,
      });

      if (sublist) {
        sublist.forEach((list: ICategoryList) => stack.push(list));
      }
    }
  }

  return elements;
}

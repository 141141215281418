import { useState } from "react";
import { IconLabelMolecule, LabelMolecule } from "..";
import { ITextValidator } from "../../../models/validator";
import { TextAreaAtom } from "../../atoms";
import styles from "./styles.module.scss";

interface Props {
  value: string;
  placeholder: string;
  onChange: (text: string) => void;
  onEnter?: () => void;
  tooltip?: string | string[];
  label?: string;
  required?: boolean;
  id?: string;
  validations?: ITextValidator[];
  disabled?: boolean;
}

export function TextAreaMolecule(props: Props) {
  const [hasBlurred, setHasBlurred] = useState(false);
  const onBlur = () => setHasBlurred(true);
  const errors = hasBlurred ? props.validations?.map((validate:ITextValidator) => {
   if (props.value?.indexOf("NaN") === -1) {
      return validate.validate(props.value);
    } else
      return validate.validate("");

  }).filter((validate:string | undefined) => validate !== undefined) : [];

  const hasError = errors && errors?.length > 0;


  return (
    <div className={styles.area}>
      <LabelMolecule
        id={props.id}
        label={props.label ?? ""}
        tooltip={props.tooltip}
        required={props.required}
      />

      <TextAreaAtom
        disabled={props.disabled}
        id={props.id}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        onBlur={onBlur}
        error={hasError}
      />
      {hasError && (
        <IconLabelMolecule
          icon={"icon-alert-red"}
          label={errors[0] ?? ""}
          type={"alert"}
        />
      )}
    </div>
  );
}

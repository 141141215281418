import { GebruikersInfo } from '../models';
import { BaseClient } from './baseClient';

export interface IAuthApi {
  Login(): void;
  Logout(): Promise<void>;
  GetUserInfo(): Promise<GebruikersInfo>;
}

export class MockAuthApi implements IAuthApi {
  Login() {
    window.location.href = new URL('http://localhost:3000/').toString();
  }

  Logout = async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, 0);
      console.log('logout');
    });
  };

  GetUserInfo = async () => {
    const json = {
      organisatie: 'mnre1034',
      lidmaatschappen: [
        "mnre1109",
        "mnre1153",
        "mnre1150",
        "mnre1018",
        "mnre1025",
        "mnre1045",
        "mnre1013",
        "mnre1130",
        "mnre1073",
        "mnre1090",
        "mnre1034",
        "mnre1010",
        "mnre1058"
      ],
    };
    return json as GebruikersInfo;
  };
}

export class AuthApi extends BaseClient implements IAuthApi {
  constructor() {
    super(
      new Headers({
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      }),
      process.env.REACT_APP_GEBRUIKERSINFO as string
    );
  }

  Login() {
    const loginUrl = new URL(process.env.REACT_APP_AUTH_API_SESSIE as string);
    window.location.href = loginUrl.toString();
  }

  Logout = async () => {
    await fetch(process.env.REACT_APP_AUTH_API_SESSIE_LOGOUT!, {
      credentials: 'include',
      headers: new Headers({
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      }),
      method: 'POST',
    }).then((response: Response) => this.handleResponse('logout', response));
  };

  GetUserInfo = async () => {
    const { json } = await this.api<GebruikersInfo>(
      'gebruikersinfo',
      undefined,
      'GET'
    );

    return json as GebruikersInfo;
  };
}

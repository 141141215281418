import { ICheckbox } from "./Checkbox";

export interface ICategoryList {
    title?: string;
    options: ICheckbox[];
    sublist?: ICategoryList[];
}

export function getSelectedItemsFromCategoryList(items: ICategoryList[]): ICheckbox[] {
    let checked: ICheckbox[] = [];
    let sublistChecks: ICheckbox[] = [];
  
    for (let item of items) {
      if (item.options?.some((option:ICheckbox) => option.checked)) {
        checked.push(...item.options.filter((option:ICheckbox) => option.checked));
      }
  
      if (item.sublist && item.sublist.length > 0) {
        sublistChecks.push(...getSelectedItemsFromCategoryList(item.sublist));
      }
    }
  
    return checked.concat(sublistChecks);
  }
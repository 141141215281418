import styles from "./styles.module.scss";

interface Props {
  verticaal?: boolean;
  type?: "primary";
}

export function DividerAtom(props: Props) {
  let className = styles.divider;

  if (props.type){
    const styleType = styles[`divider--${props.type}`];
    className += ` ${styleType}`;
  }

  if (props.verticaal){
    const styleVertical = styles[`divider--verticaal`];
    className += ` ${styleVertical}`;
  }

  return <div className={className} />;
}

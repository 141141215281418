import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { WaardelijstenApi } from "../../../api/waardelijsten";
import { Teksten } from "../../../content";
import { DropdownInfoFactory, WaardelijstFactory } from "../../../factories";
import { OrgMembershipsFactory } from "../../../factories/orgMemberships";
import { IdentifierWithChildren } from "../../../factories/waardelijst";
import { Identifier } from "../../../models";
import { IdentifierMetAfkorting } from "../../../models/identifier";
import { Bestand, Documenthandelingen, Versies } from "../../../models/metadata";
import { Roles } from "../../../models/Roles";
import { IFileValidator, IListValidator, ITextValidator } from "../../../models/validator";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  addForm,
  addReason,
  clear,
  conceptDate,
  fetchForm,
  removeDocumentHandelingSoort,
  setMode,
  updateAggegratieKenmer,
  updateAltTitel,
  updateCreatieDatum,
  updateDocumentHandelingDate,
  updateDocumentHandelingen,
  updateDocumentHandelingOrganisatie,
  updateDocumentHandelingSoort,
  updateDocumentSoorten,
  updateErrorReviewed,
  updateFile,
  updateGeldigheidBegindatum,
  updateGeldigheidEinddatum,
  updateIdentifiers,
  updateInformatiecategrieen,
  updateMedeverantwoordelijken,
  updateOmschrijving,
  updateOnderwerpen,
  updateOnderwerpenRonl,
  updateOpsteller,
  updatePublisher,
  updateTaal,
  updateThemas,
  updateTitel,
  updateVerantwoordelijke,
  updateVerkorteTitel,
  updateZichtbaarheidDate,
  updateZichtbaarheidTime,
} from "../../../store/reducers/metadata";
import { selectMetadata } from "../../../store/selectors";
import { formatDate, formatStringToDate, stringToDate, timeFormatter } from "../../../utils/DateFormatter";
import { isPublished } from "../../../utils/Metadata";
import { StringSanitizer } from "../../../utils/StringSanitizer";
import { WaardelijstCrawler } from "../../../utils/WaardelijstCrawler";
import { MaxFileSizeValidator } from "../../../validations/maxSizeValidator";
import { MinimumLengthValidator } from "../../../validations/minimumLengthValidator";
import {
  RequiredFileValidator,
  RequiredListValidator,
  RequiredValidator,
} from "../../../validations/requiredValidator";
import { CheckboxAtom, ContainerAtom, SpacerAtom } from "../../atoms";
import { ICheckbox } from "../../interfaces/Checkbox";
import {
  DateInputMolecule,
  FileInputMolecule,
  FoldableMolecule,
  FormMolecule,
  IconButtonMolecule,
  LabelMolecule,
  PopupMolecule,
  TextAreaMolecule,
  TextInputMolecule,
  TimeInputMolecule,
  TooltipMolecule,
} from "../../molecules";
import { SelectDropdownMolecule } from "../../molecules/select-dropdown";

const stringSanitizer = new StringSanitizer();

export function CreateFormulierOrganism() {
  const wlFactory = new WaardelijstFactory();
  const orgMemFactory = new OrgMembershipsFactory();
  const dropdownInfofactory = new DropdownInfoFactory();
  const waardelijstFactory = new WaardelijstenApi();

  const root = useAppSelector(selectMetadata);
  const formStates = useAppSelector((s) => s.meta.data);
  const formStateIndex = useAppSelector((s) => s.meta.formIndex);
  const documentError = useAppSelector((s) => s.meta.documentError);
  const formState = formStates[formStateIndex];
  const hoofdDoc = formStates[0];
  const authState = useAppSelector((s) => s.auth);
  const mode = useAppSelector((s) => s.meta.mode);
  const dispatch = useAppDispatch();
  const [uitgesteldPubliceren, setUitgesteldPubliceren] = useState(false);

  const [documentDatums, setDocumentDatums] = useState<string[]>([formatDate(new Date())]);
  const [popupUpdate, setPopupUpdate] = useState(false);
  const [redenUpdate, setRedenUpdate] = useState<Identifier>();
  const [redenIntrekkingVervanging, setRedenIntrekkingVervanging] = useState<IdentifierWithChildren[]>([]);
  const [ronlOnderwerpen, setRonlOnderwerpen] = useState<IdentifierWithChildren[]>([]);
  const [ministeries, setMinisteries] = useState<IdentifierWithChildren[]>([]);
  const [actieveMinisteries, setActieveMinisteries] = useState<IdentifierWithChildren[]>([]);
  const [taal, setTaal] = useState<IdentifierWithChildren[]>([]);
  const [informatiecategorieen, setInformatiecategorieen] = useState<IdentifierWithChildren[]>([]);
  const requiredRefs: HTMLInputElement[] = [];
  const [currentPublishDate, setCurrentPublishDate] = useState(new Date());
  const [currentPublishTime, setCurrentPublishTime] = useState<string>(timeFormatter(new Date()));
  const [isConcept, setConcept] = useState(false);

  const defaultNewHandeling = {
    soortHandeling: {
      id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
      label: "vaststelling",
    },
    atTime: new Date().toJSON(),
    wasAssociatedWith: {
      id: authState.organisatie[0].id,
      label: authState.organisatie[0].label,
    },
  };
  const orgMemLijst = orgMemFactory.create();
  const { id } = useParams();

  const hasDocumentErrors = () => {
    return documentError.indexList && documentError.indexList.length > 0;
  };

  const hasErrorReviewed = () => {
    return root.errorReviewed && !(hasDocumentErrors() && id && documentError.pid?.includes(id));
  };

  useMemo(() => {
    const actieveMinisteries: Identifier[] = ministeries.filter((o1: IdentifierWithChildren) =>
      orgMemLijst.some((o2: IdentifierMetAfkorting) => o1.id === o2.id && o1.label === o2.label)
    );
    actieveMinisteries.sort((a: Identifier, b: Identifier) => a.label!.localeCompare(b.label!));
    setActieveMinisteries(actieveMinisteries);
  }, [ministeries]);

  useEffect(() => {
    waardelijstFactory.getRedenIntrekkingVervanging().then(setRedenIntrekkingVervanging);
    waardelijstFactory.getRonlOnderwerpen().then(setRonlOnderwerpen);
    waardelijstFactory.getInformatiecategorieen().then(setInformatiecategorieen);
    waardelijstFactory.getTaal().then(setTaal);
    waardelijstFactory.getMinisterie().then(setMinisteries);
  }, []);

  useEffect(() => {
    if (mode === "update" && id === undefined) {
      dispatch(setMode("create"));
      dispatch(clear());
      dispatch(addForm());
      return;
    }

    if (mode === "create" && !id) {
      dispatch(clear());
      dispatch(addForm());
    }

    if (!id) return;

    dispatch(setMode("update"));

    if (!hasDocumentErrors() || hasErrorReviewed()) {
      dispatch(clear());
      dispatch(fetchForm(id));
    } else {
      dispatch(updateErrorReviewed(true));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const uitgesteldPublicatieDate = formState?.meta.document.zichtbaarheidsdatumtijd
      ? new Date(formState?.meta.document.zichtbaarheidsdatumtijd ?? "").toJSON()
      : undefined;
    const isUitgesteld = !!(
      uitgesteldPublicatieDate !== undefined && uitgesteldPublicatieDate !== conceptDate.toJSON()
    );
    const isConcept = !!(uitgesteldPublicatieDate !== undefined && uitgesteldPublicatieDate === conceptDate.toJSON());

    setUitgesteldPubliceren(isUitgesteld);
    setConcept(isConcept);
    if (formState?.meta.document.zichtbaarheidsdatumtijd && isUitgesteld) {
      setCurrentPublishDate(stringToDate(formState?.meta.document.zichtbaarheidsdatumtijd));
      setCurrentPublishTime(timeFormatter(stringToDate(formState?.meta.document.zichtbaarheidsdatumtijd)));
    }

    if (formState?.meta.document.documenthandelingen) {
      const docDatums = formState?.meta.document.documenthandelingen.map((handeling: Documenthandelingen) =>
        formatDate(stringToDate(handeling.atTime))
      );
      setDocumentDatums(docDatums);
    }
  }, [formState?.meta.document.zichtbaarheidsdatumtijd, formState?.meta.document.documenthandelingen]);

  useEffect(() => {
    if (hasDocumentErrors()) {
      requiredRefs.forEach((element: HTMLInputElement, index: number) => {
        if (index === 0) element.click();

        element.focus();
        element.blur();
      });
    }
  }, [documentError.indexList]);

  if (!formState) {
    return <></>;
  }

  const {
    classificatiecollectie,
    documenthandelingen,
    identifiers,
    language,
    titelcollectie,
    aggregatiekenmerk,
    creatiedatum,
    geldigheid,
    naamOpsteller,
    omschrijvingen,
    onderwerpen,
    verantwoordelijke,
    medeverantwoordelijken,
    publisher,
    pid,
  } = formState.meta.document;

  const requiredTextInputValidations: ITextValidator[] = [new RequiredValidator(), new MinimumLengthValidator(3)];
  const textInputValidations: ITextValidator[] = [new MinimumLengthValidator(3)];
  const fileInputValidations: IFileValidator[] = [new RequiredFileValidator(), new MaxFileSizeValidator(2000)];
  const requiredDropdownValidations: IListValidator[] = [new RequiredListValidator()];

  const publicatieStatus = formState.meta.plooiIntern?.publicatiestatus ?? "";
  const published = isPublished(root.data[0].meta);

  const verkorteTitel =
    titelcollectie.verkorteTitels && titelcollectie.verkorteTitels.length > 0 ? titelcollectie.verkorteTitels[0] : "";
  const altTitel =
    titelcollectie.alternatieveTitels && titelcollectie.alternatieveTitels.length > 0
      ? titelcollectie.alternatieveTitels[0]
      : "";

  const isNew = formState.meta.document.pid === undefined;
  const createFileName = () => formState.file?.name;
  const editFileName = () => {
    if (!isNew) {
      const versionsContainingFile = formState.meta.versies?.filter(
        (version: Versies) =>
          version.bestanden?.find(
            (bestand: Bestand) => bestand.label === "file" || bestand.label === "pdf" || bestand.label === "zip"
          ) !== undefined
      );
      const latestVersionContainingFile =
        versionsContainingFile !== undefined && versionsContainingFile[versionsContainingFile!.length - 1];

      return (
        formState.file?.name! ??
        (latestVersionContainingFile !== false && latestVersionContainingFile.bestanden
          ? latestVersionContainingFile.bestanden?.find(
              (bestand: Bestand) => bestand.label === "file" || bestand.label === "pdf" || bestand.label === "zip"
            )?.bestandsnaam
          : formState.meta.document.titelcollectie.officieleTitel)
      );
    }

    return formState.file?.name;
  };

  const setDocument = (file: File | undefined) => {
    dispatch(updateFile(file));

    if (file && pid) setPopupUpdate(true);
  };

  const enableUitgesteldPubliceren = () => {
    setUitgesteldPubliceren(!uitgesteldPubliceren);
    setConcept(false);

    if (!uitgesteldPubliceren) {
      let date = new Date();
      date.setMinutes(date.getMinutes() + 15);
      dispatch(updateZichtbaarheidDate(date.toJSON()));
      dispatch(updateZichtbaarheidTime(date.toTimeString()));
    } else {
      dispatch(updateZichtbaarheidDate(undefined));
    }
  };

  const toggleConcept = () => {
    setConcept(!isConcept);
    setUitgesteldPubliceren(false);

    if (!isConcept) {
      let date = conceptDate;
      dispatch(updateZichtbaarheidDate(date.toJSON()));
      dispatch(updateZichtbaarheidTime(date.toTimeString()));
    } else {
      dispatch(updateZichtbaarheidDate(undefined));
    }
  };

  const actievePublisher: boolean = ministeries.some(
    (ministerie: IdentifierWithChildren) => ministerie.id === publisher.id && ministerie.label === publisher.label
  );
  const disableForm: boolean =
    !actievePublisher ||
    stringSanitizer.sanitizePublicatieStatus(root.data[0].meta.plooiIntern?.publicatiestatus ?? "") === "Ingetrokken" ||
    stringSanitizer.sanitizePublicatieStatus(publicatieStatus) === "Ingetrokken";
  const dpcHoofdDoc: boolean = hoofdDoc.meta.document.pid
    ? stringSanitizer.extractIdentifierFromPid(hoofdDoc.meta.document.pid).startsWith("dpc-")
    : false;

  const onBeginDatum = (datum: string) => {
    if (datum.length === 0) {
      dispatch(updateGeldigheidBegindatum(undefined));
      return;
    }
    const newFromDate = stringToDate(datum);
    dispatch(updateGeldigheidBegindatum(newFromDate));
  };

  const documentdatum = (index: number) => {
    const wasAssociatedWith: IdentifierMetAfkorting = WaardelijstCrawler.searchOrganisationAfkorting(
      documenthandelingen[index].wasAssociatedWith
    );
    return (
      <div key={"documentdatum_" + index} style={{ position: "relative" }}>
        <ContainerAtom type="row">
          <SelectDropdownMolecule
            disabled={disableForm}
            key={Teksten.createScreen.wasAssociatedWith.id + index}
            onUpdated={(organisatie: Identifier) =>
              dispatch(
                updateDocumentHandelingOrganisatie({
                  identifier: organisatie,
                  index,
                })
              )
            }
            selected={[
              {
                id: wasAssociatedWith.id,
                label: wasAssociatedWith.afkorting ?? wasAssociatedWith.label,
              },
            ]}
            inputRef={(element: HTMLInputElement) => {
              if (documenthandelingen[index].wasAssociatedWith.id === "") requiredRefs.push(element);
            }}
            tekst={{ ...Teksten.createScreen.wasAssociatedWith, label: "" }}
            singleSelect
            search={false}
            validations={requiredDropdownValidations}
            categories={dropdownInfofactory.generateCategorlyListSelectFromList(
              {
                children: ministeries
                  .filter(
                    (iwc: IdentifierWithChildren) =>
                      stringSanitizer.extractIdentifierFromPid(iwc.id).replace(/\d/g, "") === "mnre"
                  )
                  .map((ministerieIWC: IdentifierWithChildren) => ({
                    ...ministerieIWC,
                    label: WaardelijstCrawler.searchOrganisationAfkorting(ministerieIWC).afkorting,
                  })),
              } as IdentifierWithChildren,
              [
                {
                  id: wasAssociatedWith.id,
                  label: wasAssociatedWith.afkorting,
                },
              ],
              true
            )}
          />
          <SelectDropdownMolecule
            disabled={disableForm}
            key={Teksten.createScreen.soortHandeling.id + index}
            tekst={{
              ...Teksten.createScreen.soortHandeling,
              label: "",
              id: Teksten.createScreen.soortHandeling.id + index,
            }}
            onUpdated={(handeling: Identifier) =>
              dispatch(updateDocumentHandelingSoort({ identifier: handeling, index }))
            }
            selected={[documenthandelingen[index].soortHandeling]}
            categories={dropdownInfofactory.generateCategorlyListSelectFromList(
              wlFactory.createDocumenthandelingenLijst(),
              [documenthandelingen[index].soortHandeling]
            )}
            inputRef={(element: HTMLInputElement) => {
              if (documenthandelingen[index].soortHandeling.id === "") requiredRefs.push(element);
            }}
            validations={requiredDropdownValidations}
            singleSelect
            search={false}
          />
          <DateInputMolecule
            disabled={disableForm}
            required
            key={Teksten.createScreen.atTime.id + "_" + index + "_" + documentDatums[index]}
            id={Teksten.createScreen.atTime.id + "_" + index}
            defaultValue={documentDatums[index]}
            onBlur={(date: React.ChangeEvent<HTMLInputElement>) => {
              if (date.currentTarget.value && date.currentTarget.value !== "") {
                let newDates = documentDatums;
                newDates[index] = formatDate(stringToDate(date.currentTarget.value));
                setDocumentDatums(newDates);
                dispatch(
                  updateDocumentHandelingDate({
                    date: date.currentTarget.value,
                    index,
                  })
                );
              } else {
                date.currentTarget.value = formatDate(stringToDate(documentDatums[index]));
              }
            }}
          />
          {documenthandelingen.length > 1 && (
            <div style={{ maxWidth: "min-content" }}>
              <IconButtonMolecule
                id={"remove_handeling" + index}
                title={"Verwijder handeling"}
                icon={"icon-remove-blue"}
                type={"default"}
                size={"inputfieldSize"}
                disabled={disableForm}
                onClick={() => {
                  let dates = documentDatums;
                  dates.splice(index, 1);
                  setDocumentDatums(dates);
                  dispatch(removeDocumentHandelingSoort(index));
                }}
              />
            </div>
          )}
        </ContainerAtom>
        {index !== documenthandelingen.length - 1 && <SpacerAtom space={4} />}
      </div>
    );
  };

  return (
    <>
      {popupUpdate && (
        <PopupMolecule
          title="Reden voor vervangen"
          text="U staat op het punt dit document te vervangen"
          cancelButton={{
            text: "Annuleer",
            disabled: true,
            onClick: () => {
              dispatch(updateFile(undefined));
              setPopupUpdate(false);
            },
          }}
          okeButton={{
            text: "Bevestigen",
            disabled: !redenUpdate,
            onClick: () => {
              dispatch(
                addReason({
                  pid: formState.meta.document.pid as string,
                  reasonId: redenUpdate?.id as string,
                })
              );

              setPopupUpdate(false);
              setRedenUpdate(undefined);
            },
          }}
          extraInfo={
            <SelectDropdownMolecule
              disabled={disableForm}
              key={Teksten.createScreen.reden.id}
              onUpdated={(cb: Identifier) => setRedenUpdate(cb)}
              selected={redenUpdate ? [redenUpdate] : []}
              tekst={Teksten.createScreen.reden}
              categories={dropdownInfofactory.generateCheckboxListSelect(redenIntrekkingVervanging, redenUpdate)}
              singleSelect
              required
              search={false}
              validations={[new RequiredListValidator()]}
            />
          }
        />
      )}
      <FormMolecule key={root.formUniqueKey}>
        <SpacerAtom space={9} />
        {dpcHoofdDoc && (
          <>
            <SpacerAtom space={4} />
            <i>{Teksten.createScreen.dpcupload.tekst}</i>
          </>
        )}
        <FileInputMolecule
          disabled={disableForm}
          inputRef={(ref: any) => requiredRefs.push(ref)}
          validations={fileInputValidations}
          key={"file"}
          selectedFileName={mode === "create" ? createFileName() : editFileName()}
          onSelectFile={setDocument}
          selectedInput={formState.file ?? editFileName()}
          required
        />
        <SpacerAtom space={4} />
        <SelectDropdownMolecule
          disabled={disableForm || mode === "update"}
          tekst={Teksten.createScreen.publisher}
          onUpdated={(identifier: Identifier) => dispatch(updatePublisher(identifier))}
          selected={[publisher]}
          categories={dropdownInfofactory.generateCheckboxListSelect(actieveMinisteries, publisher)}
          validations={requiredDropdownValidations}
          singleSelect
          required
        />
        <SpacerAtom space={4} />
        <LabelMolecule
          label={Teksten.createScreen.documentrelatie.label}
          tooltip={Teksten.createScreen.documentrelatie.tooltip}
        />
        <ContainerAtom type="row">
          <TextInputMolecule
            disabled
            id={Teksten.createScreen.documentrelatie.id_veld1}
            value={formStateIndex === 0 ? "HoofdDocument" : "Bijlage bij"}
          />
          <TextInputMolecule
            disabled
            id={Teksten.createScreen.documentrelatie.id_veld2}
            value={formStateIndex === 0 ? "" : hoofdDoc?.meta.document.titelcollectie.officieleTitel}
          />
        </ContainerAtom>
        <SpacerAtom space={4} />
        {!isNew && (
          <>
            <ContainerAtom type="row">
              <TextInputMolecule
                disabled
                id={Teksten.createScreen.documentstatus.id}
                value={isConcept ? "Concept" : stringSanitizer.sanitizePublicatieStatus(publicatieStatus)}
                label={Teksten.createScreen.documentstatus.label}
                tooltip={Teksten.createScreen.documentstatus.tooltip}
              />
              <TextInputMolecule
                disabled
                id={Teksten.createScreen.aanleverdatum.id}
                value={
                  formState.meta.versies?.length && formState.meta.versies[0].openbaarmakingsdatum
                    ? formatStringToDate(formState.meta.versies[0].openbaarmakingsdatum ?? "")
                    : ""
                }
                label={Teksten.createScreen.aanleverdatum.label}
                tooltip={Teksten.createScreen.aanleverdatum.tooltip}
              />
              <TextInputMolecule
                disabled
                id={Teksten.createScreen.laatstBewerkt.id}
                value={
                  formState.meta.versies?.length && formState.meta.versies[0].mutatiedatumtijd
                    ? formatStringToDate(formState.meta.versies[0].mutatiedatumtijd) ?? ""
                    : ""
                }
                label={Teksten.createScreen.laatstBewerkt.label}
                tooltip={Teksten.createScreen.laatstBewerkt.tooltip}
              />
            </ContainerAtom>
            <SpacerAtom space={4} />
          </>
        )}
        <TextInputMolecule
          disabled={disableForm}
          inputRef={(element: HTMLInputElement) => requiredRefs.push(element)}
          key={Teksten.createScreen.titel.id}
          id={Teksten.createScreen.titel.id}
          label={Teksten.createScreen.titel.label}
          placeholder={Teksten.createScreen.titel.placeholder}
          onChange={(titel: string) => dispatch(updateTitel(titel))}
          value={titelcollectie.officieleTitel}
          tooltip={Teksten.createScreen.titel.tooltip}
          required
          validations={requiredTextInputValidations}
        />
        <SpacerAtom space={4} />
        {process.env.REACT_APP_INFORMATIECATEGORIE === "true" && (
          <>
            <SelectDropdownMolecule
              disabled={disableForm}
              key={Teksten.createScreen.informatiecategorieen.id}
              onUpdated={(identifier: Identifier) => dispatch(updateInformatiecategrieen(identifier))}
              selected={classificatiecollectie.informatiecategorieen}
              tekst={Teksten.createScreen.informatiecategorieen}
              inputRef={(ref: HTMLInputElement) => {
                requiredRefs.push(ref);
              }}
              categories={dropdownInfofactory.generateCheckboxListSelect(
                informatiecategorieen.filter(
                  (iwc: IdentifierWithChildren) =>
                    iwc.id !== "https://identifier.overheid.nl/tooi/def/thes/kern/wooInformatiecategorieen" &&
                    iwc.id !== "https://identifier.overheid.nl/tooi/def/thes/kern/c_139c6280" &&
                    iwc.id !== "https://identifier.overheid.nl/tooi/def/thes/kern/c_aab6bfc7" &&
                    iwc.id !== "https://identifier.overheid.nl/tooi/def/thes/kern/c_40a05794" &&
                    iwc.id !== "https://identifier.overheid.nl/tooi/def/thes/kern/c_89ee6784" &&
                    iwc.id !== "https://identifier.overheid.nl/tooi/def/thes/kern/c_c76862ab"
                ),
                classificatiecollectie.informatiecategorieen
              )}
              validations={requiredDropdownValidations}
              singleSelect
              required
            />
            <SpacerAtom space={4} />
          </>
        )}

        <SelectDropdownMolecule
          disabled={disableForm}
          key={Teksten.createScreen.documentsoort.id}
          tekst={Teksten.createScreen.documentsoort}
          onUpdated={(ds: Identifier) => dispatch(updateDocumentSoorten(ds))}
          selected={classificatiecollectie.documentsoorten}
          inputRef={(ref: HTMLInputElement) => {
            if (classificatiecollectie.documentsoorten.length === 0) requiredRefs.push(ref);
          }}
          categories={dropdownInfofactory.generateCategorlyListSelectFromList(
            wlFactory.createDocumentsoortenLijst(authState.roles.some((ds: string) => ds === Roles.KST)),
            classificatiecollectie.documentsoorten
          )}
          validations={requiredDropdownValidations}
          required
          singleSelect
        />
        <SpacerAtom space={4} />
        <TextInputMolecule
          disabled={disableForm}
          inputRef={(element: HTMLInputElement) => requiredRefs.push(element)}
          key={Teksten.createScreen.identifier.id}
          id={Teksten.createScreen.identifier.id}
          label={Teksten.createScreen.identifier.label}
          placeholder={Teksten.createScreen.identifier.placeholder}
          onChange={(identifier: string) => {
            dispatch(updateIdentifiers([identifier]));
          }}
          value={identifiers[0] ?? ""}
          tooltip={Teksten.createScreen.identifier.tooltip}
          required
          validations={requiredTextInputValidations}
        />
        <SpacerAtom space={4} />
        <LabelMolecule
          label={Teksten.createScreen.documentdatum.label}
          tooltip={Teksten.createScreen.documentdatum.tooltip}
          required
        />
        {documenthandelingen.map((_, index: number) => {
          return documentdatum(index);
        })}
        <SpacerAtom space={4} />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <IconButtonMolecule
            disabled={disableForm}
            text="Voeg handeling toe"
            title="Voeg een extra documenthandeling toe"
            icon={"icon-plus-shape"}
            id={"add_documenthandeling_button"}
            type={"default"}
            size={"medium"}
            onClick={() => {
              setDocumentDatums([...documentDatums, formatDate(new Date())]);
              dispatch(updateDocumentHandelingen([...documenthandelingen, defaultNewHandeling]));
            }}
          />
        </div>

        <SpacerAtom space={4} />
        <SelectDropdownMolecule
          disabled={disableForm}
          key={Teksten.createScreen.taal.id}
          onUpdated={(identifier: Identifier) => dispatch(updateTaal(identifier))}
          selected={[language]}
          tekst={Teksten.createScreen.taal}
          categories={dropdownInfofactory.generateCheckboxListSelect(
            taal,
            language,
            (checkbox: ICheckbox) => checkbox.label !== "" && checkbox.label !== "taal"
          )}
          singleSelect
          required
        />
        <SpacerAtom space={4} />
        {
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckboxAtom
              disabled={formStateIndex !== 0 || published || disableForm}
              id={Teksten.createScreen.concept.id}
              checked={isConcept}
              onClick={() => toggleConcept()}
              label={Teksten.createScreen.concept.label}
            />
            <TooltipMolecule
              id={Teksten.createScreen.concept_tooltip.id}
              content={Teksten.createScreen.concept_tooltip.tooltip}
            />
          </div>
        }
        <SpacerAtom space={4} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <CheckboxAtom
            disabled={formStateIndex !== 0 || published || disableForm}
            id={Teksten.createScreen.uitgesteld.id}
            checked={uitgesteldPubliceren}
            onClick={enableUitgesteldPubliceren}
            label={Teksten.createScreen.uitgesteld.label}
          />
          <TooltipMolecule
            id={Teksten.createScreen.uitgesteld_tooltip.id}
            content={Teksten.createScreen.uitgesteld_tooltip.tooltip}
          />
        </div>
        <SpacerAtom space={4} />
        {(uitgesteldPubliceren && (
          <ContainerAtom type="row">
            <DateInputMolecule
              disabled={formStateIndex !== 0 || published || disableForm}
              key={Teksten.createScreen.uitsteldatum.id}
              id={Teksten.createScreen.uitsteldatum.id}
              placeholder={Teksten.createScreen.uitsteldatum.placeholder}
              defaultValue={formatDate(currentPublishDate)}
              onChange={(date: string) => {
                setCurrentPublishDate(new Date(date));
              }}
              onBlur={(date: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentPublishDate(new Date(date.currentTarget.value));
                dispatch(updateZichtbaarheidDate(date.currentTarget.value));
                if (date.currentTarget.value === "" || new Date(date.currentTarget.value) < new Date())
                  date.currentTarget.value = formatDate(new Date());
              }}
            />
            <TimeInputMolecule
              disabled={formStateIndex !== 0 || published || disableForm}
              minDate={geldigheid?.begindatum ? stringToDate(geldigheid?.begindatum) : undefined}
              key={Teksten.createScreen.uitsteltijd.id}
              id={Teksten.createScreen.uitsteltijd.id}
              placeholder={Teksten.createScreen.uitsteltijd.placeholder}
              value={currentPublishTime}
              onChange={(time: string) => {
                setCurrentPublishTime(time);
              }}
              onBlur={(time: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(updateZichtbaarheidTime(time.currentTarget.value));
                setCurrentPublishTime(
                  timeFormatter(
                    formState?.meta.document.zichtbaarheidsdatumtijd
                      ? stringToDate(formState?.meta.document.zichtbaarheidsdatumtijd)
                      : new Date()
                  )
                );
              }}
            />
          </ContainerAtom>
        )) ||
          (!uitgesteldPubliceren && <SpacerAtom space={"58px"} />)}
        <SpacerAtom space={4} />
        <FoldableMolecule label={"Optionele velden"} defaultFolded={false}>
          <SpacerAtom space={4} />
          <SelectDropdownMolecule
            disabled={disableForm}
            key={Teksten.createScreen.verantwoordelijke.id}
            tekst={Teksten.createScreen.verantwoordelijke}
            onUpdated={(identifier: Identifier) => dispatch(updateVerantwoordelijke(identifier))}
            selected={verantwoordelijke ? [verantwoordelijke] : []}
            categories={dropdownInfofactory.generateCheckboxListSelect(
              ministeries,
              verantwoordelijke,
              (checkbox: ICheckbox) =>
                stringSanitizer.extractIdentifierFromPid(checkbox.id).replace(/\d/g, "") === "mnre"
            )}
            singleSelect
          />
          <SpacerAtom space={4} />
          <SelectDropdownMolecule
            disabled={disableForm}
            onUpdated={(identifier: Identifier) => dispatch(updateMedeverantwoordelijken(identifier))}
            selected={medeverantwoordelijken ?? []}
            tekst={Teksten.createScreen.medeverantwoordelijken}
            key={Teksten.createScreen.medeverantwoordelijken.id}
            categories={dropdownInfofactory.generateCheckboxListSelect(
              ministeries,
              medeverantwoordelijken,
              (checkbox: ICheckbox) =>
                stringSanitizer.extractIdentifierFromPid(checkbox.id).replace(/\d/g, "") === "mnre"
            )}
          />
          <SpacerAtom space={4} />
          <TextInputMolecule
            disabled={disableForm}
            key={Teksten.createScreen.onderwerpen.id}
            id={Teksten.createScreen.onderwerpen.id}
            label={Teksten.createScreen.onderwerpen.label}
            placeholder={Teksten.createScreen.onderwerpen.placeholder}
            value={onderwerpen && onderwerpen.length > 0 ? onderwerpen[0] : ""}
            onChange={(onderwerp: string) => dispatch(updateOnderwerpen([onderwerp]))}
            tooltip={Teksten.createScreen.onderwerpen.tooltip}
            validations={textInputValidations}
          />
          <SpacerAtom space={4} />
          <SelectDropdownMolecule
            disabled={disableForm}
            onUpdated={(identifier: Identifier) => dispatch(updateOnderwerpenRonl(identifier))}
            selected={classificatiecollectie.onderwerpenRonl ?? []}
            key={Teksten.createScreen.onderwerpenRonl.id}
            tekst={Teksten.createScreen.onderwerpenRonl}
            categories={dropdownInfofactory.generateCheckboxListSelect(
              ronlOnderwerpen,
              classificatiecollectie.onderwerpenRonl
            )}
          />
          <SpacerAtom space={4} />
          <SelectDropdownMolecule
            disabled={disableForm}
            key={Teksten.createScreen.thema.id}
            tekst={Teksten.createScreen.thema}
            categories={dropdownInfofactory.generateCategorlyListSelectFromList(
              wlFactory.createThemaLijst(),
              classificatiecollectie.themas ?? []
            )}
            onUpdated={(identifier: Identifier) => dispatch(updateThemas(identifier))}
            selected={classificatiecollectie.themas ?? []}
          />
          <SpacerAtom space={4} />
          <TextAreaMolecule
            disabled={disableForm}
            key={Teksten.createScreen.omschrijving.id}
            id={Teksten.createScreen.omschrijving.id}
            label={Teksten.createScreen.omschrijving.label}
            placeholder={Teksten.createScreen.omschrijving.placeholder}
            value={omschrijvingen && omschrijvingen?.length > 0 ? omschrijvingen[0] : ""}
            onChange={(desc: string) => dispatch(updateOmschrijving(desc))}
            tooltip={Teksten.createScreen.omschrijving.tooltip}
            validations={textInputValidations}
          />
          <SpacerAtom space={4} />
          <TextInputMolecule
            disabled={disableForm}
            key={Teksten.createScreen.verkorteTitels.id}
            id={Teksten.createScreen.verkorteTitels.id}
            label={Teksten.createScreen.verkorteTitels.label}
            placeholder={Teksten.createScreen.verkorteTitels.placeholder}
            value={verkorteTitel}
            onChange={(value: string) => dispatch(updateVerkorteTitel(value))}
            tooltip={Teksten.createScreen.verkorteTitels.tooltip}
            validations={textInputValidations}
          />
          <SpacerAtom space={4} />
          <TextInputMolecule
            disabled={disableForm}
            key={Teksten.createScreen.alternatieveTitels.id}
            id={Teksten.createScreen.alternatieveTitels.id}
            label={Teksten.createScreen.alternatieveTitels.label}
            placeholder={Teksten.createScreen.alternatieveTitels.placeholder}
            value={altTitel}
            onChange={(value: string) => dispatch(updateAltTitel(value))}
            tooltip={Teksten.createScreen.alternatieveTitels.tooltip}
            validations={textInputValidations}
          />
          <SpacerAtom space={4} />
          <TextInputMolecule
            disabled={disableForm}
            key={Teksten.createScreen.naamOpsteller.id}
            id={Teksten.createScreen.naamOpsteller.id}
            label={Teksten.createScreen.naamOpsteller.label}
            placeholder={Teksten.createScreen.naamOpsteller.placeholder}
            value={naamOpsteller}
            onChange={(value: string) => dispatch(updateOpsteller(value))}
            tooltip={Teksten.createScreen.naamOpsteller.tooltip}
            validations={textInputValidations}
          />
          <SpacerAtom space={4} />
          <TextInputMolecule
            disabled={disableForm}
            key={Teksten.createScreen.creatiedatum.id}
            id={Teksten.createScreen.creatiedatum.id}
            label={Teksten.createScreen.creatiedatum.label}
            placeholder={Teksten.createScreen.creatiedatum.placeholder}
            value={creatiedatum}
            onChange={(value: string) => dispatch(updateCreatieDatum(value))}
            tooltip={Teksten.createScreen.creatiedatum.tooltip}
            validations={textInputValidations}
          />
          <SpacerAtom space={4} />
          <LabelMolecule
            label={Teksten.createScreen.geldigheid.label}
            tooltip={Teksten.createScreen.geldigheid.tooltip}
          />
          <ContainerAtom type="row">
            <DateInputMolecule
              disabled={disableForm}
              key={Teksten.createScreen.begindatum.id}
              id={Teksten.createScreen.begindatum.id}
              placeholder={Teksten.createScreen.begindatum.placeholder}
              value={geldigheid?.begindatum ? formatDate(stringToDate(geldigheid.begindatum)) : undefined}
              onChange={onBeginDatum}
            />
            <DateInputMolecule
              disabled={disableForm}
              minDate={geldigheid?.begindatum ? stringToDate(geldigheid.begindatum) : undefined}
              key={Teksten.createScreen.einddatum.id}
              id={Teksten.createScreen.einddatum.id}
              placeholder={Teksten.createScreen.einddatum.placeholder}
              value={geldigheid?.einddatum ? formatDate(stringToDate(geldigheid?.einddatum)) : undefined}
              onChange={(geldigheidEindDatum: string) =>
                dispatch(
                  updateGeldigheidEinddatum(geldigheidEindDatum.length ? stringToDate(geldigheidEindDatum) : undefined)
                )
              }
            />
          </ContainerAtom>
          <SpacerAtom space={4} />
          <TextInputMolecule
            disabled={disableForm}
            key={Teksten.createScreen.aggregatiekenmerk.id}
            id={Teksten.createScreen.aggregatiekenmerk.id}
            label={Teksten.createScreen.aggregatiekenmerk.label}
            placeholder={Teksten.createScreen.aggregatiekenmerk.placeholder}
            value={aggregatiekenmerk}
            onChange={(v: string) => dispatch(updateAggegratieKenmer(v))}
            tooltip={Teksten.createScreen.aggregatiekenmerk.tooltip}
            validations={textInputValidations}
          />
        </FoldableMolecule>
      </FormMolecule>
    </>
  );
}

import { useEffect, useState } from "react";
import { WaardelijstenApi } from "../../../api/waardelijsten";
import { Teksten } from "../../../content/teksten";
import { DropdownInfoFactory } from "../../../factories";
import { IdentifierWithChildren } from "../../../factories/waardelijst";
import { Identifier, Metadata } from "../../../models";
import { PUBLICATIE_STATUS } from "../../../models/metadata";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addForm, changeFormIndex, create, removeForm, unpublish, update } from "../../../store/reducers/metadata";
import { selectMetadata } from "../../../store/selectors";
import { matchStatus } from "../../../utils/Metadata";
import { StringSanitizer } from "../../../utils/StringSanitizer";
import { RequiredListValidator } from "../../../validations/requiredValidator";
import { ButtonLookALikeAtom, ContainerAtom, HeadingH2Atom, IconAtom, SpacerAtom, TableAtom } from "../../atoms";
import { RowState } from "../../atoms/table";
import { ButtonMolecule, IconButtonMolecule, PopupMolecule, SpinnerPopupMolecule } from "../../molecules";
import { SelectDropdownMolecule } from "../../molecules/select-dropdown";

const stringSanitizer = new StringSanitizer();

interface UploadRow {
  title: JSX.Element;
  button: JSX.Element;
}

function DeleteButton(props: {
  meta: Metadata;
  mode: "create" | "update";
  onRemove: () => any;
  onDepublish: () => any;
  index: number;
  disabled: boolean;
}) {
  const isNew = props.meta.document.pid === undefined || props.meta.document.pid.length <= 0;

  if (matchStatus(props.meta, PUBLICATIE_STATUS.ingetrokken)) return <></>;

  return (
    <IconButtonMolecule
      icon={"icon-remove-blue"}
      size={"medium"}
      key={`remove_upload_${props.index}`}
      id={`remove_upload_${props.index}`}
      title={
        "Trek dit document in. Een ingetrokken document is niet meer zichtbaar op open.overheid.nl, de metadata zullen nog wel beschikbaar zijn."
      }
      onClick={() => {
        if (isNew) props.onRemove();
        else props.onDepublish();
      }}
      type={"default"}
      square
      disabled={props.disabled}
    />
  );
}

export function SidebarOrganism() {
  const dropdownInfofactory = new DropdownInfoFactory();
  const waardelijstFactory = new WaardelijstenApi();
  const state = useAppSelector(selectMetadata);

  const publishDisabled = !!(
    state.data.length > state.formIndex &&
    state.data[state.formIndex].meta.plooiIntern?.publicatiestatus === "_intrekking"
  );
  const dispatch = useAppDispatch();
  const mode = useAppSelector((s) => s.meta.mode);
  const fetching = useAppSelector((s) => s.meta.fetching);

  const [popupDepubliceren, setPopupDepubliceren] = useState(false);
  const [spinnerDepubliceren, setSpinnerDepubliceren] = useState(false);
  const [popupErrors, setPopupErrors] = useState(false);
  const [fetchingText, setFetchingText] = useState<string | undefined>(undefined);
  const [redenDepubliceren, setRedenDepubliceren] = useState<Identifier>();
  const [depubliceerPid, setPidDepubliceren] = useState<string>();
  const [redenIntrekkingVervanging, setRedenIntrekkingVervanging] = useState<IdentifierWithChildren[]>([]);
  const [ministerie, setMinisterie] = useState<IdentifierWithChildren[]>([]);
  const documentErrorIndexList = useAppSelector((s) => s.meta.documentError.indexList);
  const dpcUpload =
    state.data.length > 0 && state.data[0].meta.document.pid
      ? stringSanitizer.extractIdentifierFromPid(state.data[0].meta.document.pid).startsWith("dpc-")
      : false;

  const actievePublisher = state.data[state.formIndex]?.meta!
    ? ministerie.some(
        (ministerie: IdentifierWithChildren) =>
          ministerie.id === state.data[state.formIndex].meta.document.publisher.id &&
          ministerie.label === state.data[state.formIndex].meta.document.publisher.label
      )
    : false;
  const bijlageToevoegenDisabled = !!(
    state.data.length && state.data[0].meta.plooiIntern?.publicatiestatus === "_intrekking"
  );

  useEffect(() => {
    waardelijstFactory.getRedenIntrekkingVervanging().then(setRedenIntrekkingVervanging);
    waardelijstFactory.getMinisterie().then(setMinisterie);
  }, []);

  useEffect(() => {
    setPopupErrors((documentErrorIndexList?.length ?? 0) > 0 && documentErrorIndexList![0] !== 0);
  }, [documentErrorIndexList]);

  useEffect(() => {
    setFetchingText(undefined);
    if (fetching) {
      if (state.data.length <= 0) {
        setFetchingText("Eén moment geduld, documenten worden opgehaald");
      } else if (spinnerDepubliceren) {
        setFetchingText("Eén moment geduld, document wordt ingetrokken");
      } else if (mode === "create") {
        setFetchingText("Eén moment geduld, document wordt geüpload");
      } else if (mode === "update") {
        setFetchingText("Eén moment geduld, document wordt bewerkt");
      }
    } else if (spinnerDepubliceren) {
      setSpinnerDepubliceren(false);
    }
  }, [fetching, spinnerDepubliceren]);

  const removeAttachment = (index: number) => dispatch(removeForm(index));
  const addAttachment = () => dispatch(addForm());
  const onUpload = () => {
    if (mode === "create") {
      dispatch(create());
      return;
    }

    dispatch(update(true));
  };
  const onChangeIndex = (index: number) => {
    dispatch(changeFormIndex(index));
  };

  const hasDeleteButton = (index: number) => {
    return !dpcUpload && !(state.mode === "create" && index === 0);
  };

  const dropdownRedenDepubliceer = () => {
    const listValidations = [new RequiredListValidator()];

    return (
      <>
        <SelectDropdownMolecule
          onUpdated={(selectedIdentifier) => setRedenDepubliceren(selectedIdentifier)}
          selected={redenDepubliceren ? [redenDepubliceren] : []}
          key={Teksten.sidebardOrganism.reden.id}
          tekst={Teksten.sidebardOrganism.reden}
          categories={dropdownInfofactory.generateCheckboxListSelect(redenIntrekkingVervanging, redenDepubliceren)}
          singleSelect
          required
          search={false}
          validations={listValidations}
        />
        {depubliceerPid === state.data[0].meta.document.pid && (
          <p>Let op: als u een hoofddocument intrekt, dan worden ook alle bijlagen ingetrokken.</p>
        )}
      </>
    );
  };

  const rows = state.data.map((result, index) => {
    let title = result.meta.document.titelcollectie.officieleTitel;

    if (!title) title = index === 0 ? "Hoofddocument" : `Bijlage ${index}`;

    let paddingLeft = index === 0 ? 0 : 24;

    return {
      title: (
        <div style={{ paddingLeft }}>
          <ButtonLookALikeAtom
            id={`document_upload_${index}`}
            onClick={() => {
              onChangeIndex(index);
            }}
          >
            {title}
          </ButtonLookALikeAtom>
        </div>
      ),
      button: hasDeleteButton(index) ? (
        <DeleteButton
          disabled={publishDisabled || dpcUpload}
          meta={result.meta}
          mode={mode}
          onDepublish={() => {
            setPidDepubliceren(result.meta.document.pid);
            setPopupDepubliceren(!popupDepubliceren);
          }}
          onRemove={() => removeAttachment(index)}
          index={index}
        />
      ) : (
        <></>
      ),
    };
  });

  return (
    <ContainerAtom type="flex">
      <HeadingH2Atom>{mode === "create" ? "Documenten publiceren" : "Documenten bewerken"}</HeadingH2Atom>
      <SpacerAtom space={4} />
      <TableAtom
        theads={["Gerelateerde documenten", ""]}
        rows={rows.map((row: UploadRow) => [row.title, row.button])}
        columnWidth={["82%", "auto"]}
        highlight={state.formIndex}
        states={rows.map((_, index: number) => {
          const errors = documentErrorIndexList?.find((errorIndex: number) => errorIndex === index);
          if (errors !== undefined && errors !== 0) {
            return { index: index, state: RowState.error };
          }
          if (state.data[index].meta.document.pid !== undefined) {
            return { index: index, state: RowState.success };
          }
          return { index: index, state: RowState.default };
        })}
      />
      <SpacerAtom space={4} />
      <ContainerAtom type="between">
        <ButtonMolecule
          text={"" + (mode === "create" ? "Document(en) publiceren" : "Wijzigingen publiceren")}
          id={"document_publiceren"}
          title={"Start hier met het publiceren van uw documenten."}
          onClick={onUpload}
          type={"primary"}
          disabled={publishDisabled || !actievePublisher}
        />
        <IconButtonMolecule
          text={"Voeg bijlage toe"}
          id={"voeg_bijlage_toe"}
          title={"Voeg hier een bijlage toe."}
          onClick={addAttachment}
          type={"default"}
          icon={"icon-plus-shape"}
          disabled={bijlageToevoegenDisabled || !actievePublisher}
        />
      </ContainerAtom>

      {popupDepubliceren && (
        <PopupMolecule
          title={Teksten.sidebardOrganism.reden.title}
          text={Teksten.sidebardOrganism.reden.tekst}
          cancelButton={{
            text: "Annuleer",
            onClick: () => {
              setPopupDepubliceren(!popupDepubliceren);
            },
          }}
          okeButton={{
            text: "Bevestigen",
            onClick: () => {
              if (redenDepubliceren) {
                dispatch(unpublish({ id: depubliceerPid as string, reden: redenDepubliceren.id }));
                setPopupDepubliceren(!popupDepubliceren);
                setSpinnerDepubliceren(true);
              }
            },
          }}
          extraInfo={dropdownRedenDepubliceer()}
        />
      )}

      {popupErrors && (
        <PopupMolecule
          title="Aanleverfouten"
          text={
            <div>
              <p>
                Uw documenten zijn aangeleverd. In deze set zijn{" "}
                {state.data.length - (documentErrorIndexList?.length ?? 0)} document(en) goed verwerkt, deze zijn groen
                getoond in de tabel.
              </p>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <IconAtom icon={"icon-alert-red"} size={"large"} alt={""} />
                <p>{documentErrorIndexList?.length ?? 0} document(-en) bevatte(n) een fout.</p>
              </div>
              <p>U wordt verzocht deze fout(-en) te herstellen en de document(en) opnieuw aan te leveren.</p>
            </div>
          }
          cancelButton={{
            text: "Oke",
            onClick: () => {
              setPopupErrors(!popupErrors);
            },
          }}
        />
      )}

      {fetchingText && <SpinnerPopupMolecule text={fetchingText} />}
    </ContainerAtom>
  );
}

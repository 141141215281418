export const stringToDate = (value: string) => {
  if(!value)
    return new Date();

  const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

  return new Date(value.replace(pattern, "$3-$2-$1"));
};

export const toDateHourMinutes = (value: string) => {
  if (!value || value.indexOf("T") === -1) return "";

  const parts = value.split("T");
  const d = parts[0];
  const t = parts[1].substring(0, 5);

  return `${d} ${t}`;
};

export const padTo2Digits = (num: number, pad:number) => {
  return num.toString().padStart(pad, "0");
};

export const formatDate = (date = new Date()) => {
  return [
    padTo2Digits(date.getFullYear(), 4),
    padTo2Digits(date.getMonth() + 1, 2),
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
  ].join("-");
};

export const formatStringToDate = (value: string) => {
  if (!value || value.indexOf("T") === -1) {
    const newDate = new Date(value);
    return [
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate(),
      padTo2Digits(newDate.getMonth() + 1, 2),
      padTo2Digits(newDate.getFullYear(), 4),
    ].join("-");
  }

  const date = new Date(value);
  const d = [
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
    padTo2Digits(date.getMonth() + 1, 2),
    padTo2Digits(date.getFullYear(), 4),
  ].join("-");
  const t = [
      date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
  ].join(":");
  return `${d} ${t}`;
};

export const convertTZ = (date: string) => {
  return new Date(
    new Date(date).toLocaleString("en-GB", { timeZone: "Europe/Amsterdam" })
  );
};

export const timeFormatter = (date: Date) => {
  return (date?.getHours()! < 10 ? '0' : '') + date?.getHours() + ':' + (date?.getMinutes()! < 10 ? '0' : '') + date?.getMinutes()
};

import styles from './styles.module.scss';

interface Props {
    space: 1 | 2 | 4 | 8 | 9 | 16 | "18px" | "58px",
    type?: "vertical" | "horizontal"
}

export function SpacerAtom(props:Props){
    const className = !props.type || props.type === "vertical"? styles[`spacer__${props.space}`] : styles[`spacer_vertical__${props.space}`];

   return <div data-testid={"spacer"} className={className} />
}
import { Sorting, SortingOrder } from "../models";
import { Documentrelaties } from "../models/metadata";
import { SearchResults } from "../models/search-result";
import { Filters } from "../models/sorting";
import { isBijlageDocument } from "../utils/Relaties";
import { BaseClient } from "./baseClient";

export interface ISearchApi {
  searchOrgDocuments(
    page: number,
    sorteer: Sorting,
    filters?: Filters,
    order?: SortingOrder,
    search?: string
  ): Promise<SearchResults>;
}

export class SearchApi extends BaseClient implements ISearchApi {
  constructor() {
    super(
      new Headers({
        "Content-type": "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      }),
      process.env.REACT_APP_ZOEKEN_API_ENDPOINT as string
    );
  }

  searchOrgDocuments = async (
    from: number,
    sorteer: Sorting,
    filters: Filters,
    order?: SortingOrder,
    search?: string
  ) => {
    const params = `start=${from}&sorteer=${sorteer}&order=${order}${
      filters ? `&filters=${filters}` : ""
    }${search ? `&q=${search}` : ""}`;
    const { json } = await this.api<SearchResults>(
      "_zoek",
      undefined,
      "GET",
      undefined,
      params
    );

    return json as SearchResults;
  };
}

export class MockSearchApi extends BaseClient implements ISearchApi {
  constructor() {
    super(
      new Headers({
        "Content-type": "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      }),
      process.env.REACT_APP_ZOEKEN_API_ENDPOINT as string
    );
  }

  searchOrgDocuments = async (
    from: number,
    sorteer: Sorting,
    filters: Filters,
    order?: SortingOrder,
    search?: string
  ) => {
    if (from !== undefined && from < 0) throw new Error("Ongeldige pagina");

    const json = jsonSearch;

    if (filters === "hoofddocument") {
      let returnValue = json;
      returnValue.resultaten = json.resultaten.filter(
        (f: { documentrelaties: Documentrelaties[] }) =>
          !isBijlageDocument(f.documentrelaties)
      );
      return returnValue;
    } else {
      return json;
    }
  };
}

const jsonSearch = {
  totaal: 3449,
  start: 0,
  aantal: 25,
  resultaten: [
    {
      document: {
        pid: "https://open.overheid.nl/documenten/6944e6db-8a2a-4881-a958-f4363820703b",
        creatiedatum: "2023-10-02T07:28:06.677Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC021023 - 0928"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "document met bijlagen",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-10-02T07:28:06.677Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-10-02T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-10-02T07:29:27.048483469Z",
          openbaarmakingsdatum: "2023-10-02",
          bestanden: [
            {
              bestandsnaam: "1MB.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [
        {
          pid: "https://open.overheid.nl/documenten/18913ebf-dc1b-433f-9dd0-78e4c0ec7649",
          soort: "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3",
          relation:
            "https://open.overheid.nl/documenten/18913ebf-dc1b-433f-9dd0-78e4c0ec7649",
          role: "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3",
        },
      ],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/78893370-ac1f-4933-9b46-ec7a5916faaa",
        creatiedatum: "2023-10-02T09:22:50.059Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["Dit is identifier"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "EPtestcase hfd met bijlage met ontbrekende metadata",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-10-02T09:22:50.059Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-10-02T12:00:00Z",
        },
        zichtbaarheidsdatumtijd: "9999-12-31T12:00:00Z",
      },
      versies: [
        {
          mutatiedatumtijd: "2023-10-02T09:24:37.187869915Z",
          openbaarmakingsdatum: "2025-10-02",
          bestanden: [
            {
              bestandsnaam: "Test kamerstuk als pdf.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "latere-publicatie",
      },
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/6944e6db-8a2a-4881-a958-f4363820703b",
        creatiedatum: "2023-10-02T07:28:06.677Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC021023 - 0928"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC021023 - 0928",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-10-02T07:28:06.677Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-10-02T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-10-02T07:29:27.048483469Z",
          openbaarmakingsdatum: "2023-10-02",
          bestanden: [
            {
              bestandsnaam: "1MB.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [
        {
          pid: "https://open.overheid.nl/documenten/18913ebf-dc1b-433f-9dd0-78e4c0ec7649",
          soort: "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3",
          relation:
            "https://open.overheid.nl/documenten/18913ebf-dc1b-433f-9dd0-78e4c0ec7649",
          role: "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3",
        },
      ],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/b38b3131-d797-4948-89c3-31b1319a23a5",
        creatiedatum: "2023-10-02T07:23:37.536Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC021023 - 0926"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC021023 - 0926",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-10-02T07:23:37.536Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-10-02T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-10-02T07:26:52.933244081Z",
          openbaarmakingsdatum: "2023-10-02",
          bestanden: [
            {
              bestandsnaam: "1MB.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [
        {
          relation:
            "https://open.overheid.nl/documenten/7b7d6184-7ca0-4538-914c-cc9fb26f95e9",
          role: "https://identifier.overheid.nl/tooi/def/thes/kern/c_4d1ea9ba",
        },
      ],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/55629665-cc95-40c0-8000-1e74bb221f99",
        creatiedatum: "2023-09-29T14:50:20.193Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["test"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "Sample",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T14:50:20.193Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T14:50:29.858130101Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "Sample.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/c71e3468-a1dd-4554-bc89-cd541f901e8c",
        creatiedatum: "2023-09-29T14:04:27.837Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["test"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "Sample",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T14:04:27.837Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T14:04:38.659175759Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "Sample.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/54899a2e-306b-4351-87ae-d3226e6aec63",
        creatiedatum: "2023-09-29T11:52:56.095Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC290923 - 1353"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC290923 - 1353",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T11:52:56.095Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T11:54:00.366193817Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "1MB.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/2a027b9b-08cb-4ac3-90fd-791983f4654f",
        creatiedatum: "2023-09-29T11:15:26.301Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC290923 - 1315"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "UPDATE - DOC290923 - 1315",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T11:15:26.301Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T11:38:42.810172062Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-...-1c1dfb3.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [
        {
          pid: "https://open.overheid.nl/documenten/282dbe51-d996-4dc0-ac7b-e1bbbe0bb211",
          soort: "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3",
          relation:
            "https://open.overheid.nl/documenten/282dbe51-d996-4dc0-ac7b-e1bbbe0bb211",
          role: "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3",
        },
      ],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/6141a794-08e1-4a42-90f2-fd0d0caeafe6",
        creatiedatum: "2023-09-29T11:07:59.654Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC290923 - 1308"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC290923 - 1308",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T11:07:59.654Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T11:08:43.658839772Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "1MB.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/38d1c665-a893-460b-9bc2-619f34b48c9c",
        creatiedatum: "2023-09-29T11:01:25.867Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC290923 - 1301"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC290923 - 1301",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T11:01:25.867Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T11:02:15.053906031Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "1MB.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/86d6831c-1b2f-4a60-ba30-57d56ef0ef65",
        creatiedatum: "2023-09-29T10:58:44.507Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC290923 - 1259"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC290923 - 1259",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T10:58:44.507Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T11:00:33.397376895Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "1MB.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/70417d6b-736f-4699-bc08-791dfe1c9c98",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC391d4703-a55a-467d-8240-0543566a75af"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC391d4703-a55a-467d-8240-0543566a75af",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T10:54:35.880813273Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/11402aa2-a80e-4c0b-8ad6-3c0d89e88907",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC89386331-467e-4e1e-80f1-422172075a69"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC89386331-467e-4e1e-80f1-422172075a69",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T10:39:17.646918447Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/21e869e3-097b-421a-96f6-4117f2c4ef8f",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOCd4185cc5-3c45-4056-8eca-6e49379f95ae"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOCd4185cc5-3c45-4056-8eca-6e49379f95ae",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T10:32:59.26904659Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/3c9f3de6-785d-4853-bd74-022b221eb866",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC2f9aaaa0-73e3-4037-9b13-55e5b074d57a"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC2f9aaaa0-73e3-4037-9b13-55e5b074d57a",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T10:31:07.07077336Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/ead85bf4-0ab2-4b7a-8ff8-c18f613e6c20",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC8d08abf2-dfec-42c9-91e5-3fc4605dc624"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC8d08abf2-dfec-42c9-91e5-3fc4605dc624",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T10:30:14.345842343Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/bda2752f-eb41-4399-a7c1-afa3aa893069",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOCee5abd87-8d9a-4b84-9f45-5fb34b44f783"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOCee5abd87-8d9a-4b84-9f45-5fb34b44f783",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:50:38.576142776Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/e790a811-de84-46d2-a8ee-9ffa65199d5f",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["undefinedd2335776-c83f-4c98-99bc-f05cdd9b50ab"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "undefinedd2335776-c83f-4c98-99bc-f05cdd9b50ab",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:38:56.670582335Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/357be2ec-2c21-46ef-b3b5-e35986defded",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOCf185f608-ae6d-408f-b506-d2e96152e385"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOCf185f608-ae6d-408f-b506-d2e96152e385",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:35:55.020037043Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/9cd40b1a-62be-4e97-a0d2-b7a5425aa134",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOC57bc4913-feb5-416e-bdd1-eaef419236af"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOC57bc4913-feb5-416e-bdd1-eaef419236af",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:34:46.274296714Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/ee1019ca-6376-49f1-b64f-cbe02448fa0d",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOCb976af5a-8639-4993-966c-7f2aecdbb598"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOCb976af5a-8639-4993-966c-7f2aecdbb598",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:32:39.323461881Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/6c03cf1a-9fb9-4a35-ac79-4c7f975d1ada",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOCb37f83e1-da25-4845-a1ba-671209a39708"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOCb37f83e1-da25-4845-a1ba-671209a39708",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:31:37.773141832Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/d305f718-4586-4150-9aa4-bf223fb530ed",
        creatiedatum: "2023-09-29T12:00:00Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["DOCe8256481-4c97-47d9-bd6f-e03af764d708"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "DOCe8256481-4c97-47d9-bd6f-e03af764d708",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_49599e6c",
              label: "Kamerbrief",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535",
              label: "ontvangst",
            },
            atTime: "2022-04-25T14:15:22Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:31:05.343278936Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam:
                "Kamerbrief over voorbereiding implementatie Wet open overheid.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/b3fd6720-98c1-4f97-a837-de6c08a171f2",
        creatiedatum: "2023-09-29T09:27:05.142Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["test"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "Sample update",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_11305262",
              label: "memorie van toelichting",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T09:27:05.142Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:27:36.760587075Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "Sample.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [
        {
          pid: "https://open.overheid.nl/documenten/c7dd3e29-958b-419b-b87b-b55426033df6",
          soort: "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3",
          relation:
            "https://open.overheid.nl/documenten/c7dd3e29-958b-419b-b87b-b55426033df6",
          role: "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3",
        },
      ],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/777af904-6268-4b7e-ae80-bb0b63b6f8fb",
        creatiedatum: "2023-09-29T09:26:32.741Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["test"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "Sample ",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T09:26:32.741Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:26:57.46438123Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "Sample.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
    {
      document: {
        pid: "https://open.overheid.nl/documenten/8320abb6-ebed-4b3e-90b2-eb8bd1cdd806",
        creatiedatum: "2023-09-29T09:23:13.042Z",
        publisher: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        verantwoordelijke: {
          id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
          label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
        },
        opsteller: {
          id: "https://identifier.overheid.nl/tooi/id/organisatie_onbekend",
          label: "Onbekend",
        },
        identifiers: ["test"],
        language: {
          id: "http://publications.europa.eu/resource/authority/language/NLD",
          label: "Nederlands",
        },
        titelcollectie: {
          officieleTitel: "Sample test",
        },
        classificatiecollectie: {
          documentsoorten: [
            {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52",
              label: "nader rapport",
            },
          ],
          themas: [
            {
              id: "https://identifier.overheid.nl/tooi/id/thema_onbekend",
              label: "Onbekend",
            },
          ],
        },
        documenthandelingen: [
          {
            soortHandeling: {
              id: "https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76",
            },
            atTime: "2023-09-29T09:23:13.042Z",
            wasAssociatedWith: {
              id: "https://identifier.overheid.nl/tooi/id/ministerie/mnre1034",
              label: "ministerie van Binnenlandse Zaken en Koninkrijksrelaties",
            },
          },
        ],
        publicatiebestemming: ["rijksoverheid.nl"],
        geldigheid: {
          begindatum: "2023-09-29T12:00:00Z",
        },
      },
      versies: [
        {
          mutatiedatumtijd: "2023-09-29T09:23:49.551285123Z",
          openbaarmakingsdatum: "2023-09-29",
          bestanden: [
            {
              bestandsnaam: "Sample.pdf",
              label: "file",
            },
          ],
        },
      ],
      plooiIntern: {
        publicatiestatus: "gepubliceerd",
      },
      documentrelaties: [],
    },
  ],
} as any;

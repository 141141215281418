import { IdentifierMetAfkorting } from "../models/identifier";
import { useAppSelector } from "../store/hooks";

export class OrgMembershipsFactory {
  public create = (): IdentifierMetAfkorting[] => {
    const authState = useAppSelector(s => s.auth);
    const memberships = authState.organisatie.concat(authState.lidmaatschappen).filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
    return memberships.sort((a, b) => a.label!.localeCompare(b.label!));
  };
}

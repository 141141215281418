import { WaardelijstFactory } from "../factories";

const REDENINTREKKINGVERVANGING = "plooi:RedenIntrekkingVervanging";
const ONDERWERPEN_RONL = "rijksoverheid.nl:onderwerp";
const TAAL = "eu:Taal";
const INFORMATIECATEGORIEEN = "woo:Informatiecategorie";
const MINISTERIE = "overheid:Ministerie";

export class WaardelijstenApi {
  private wlFactory: WaardelijstFactory;
  private baseUrl: string;

  constructor() {
    this.baseUrl = `${process.env.REACT_APP_DICTIONARY_API}`;
    this.wlFactory = new WaardelijstFactory();
  }

  private getWaardelijst = (subject: string, fallbackResponse: any) =>
    fetch(`${this.baseUrl}/${subject}`)
      .then((response: Response) => response.json())
      .then(this.wlFactory.createFromTooi)
      .catch(() => fallbackResponse);

  public getRedenIntrekkingVervanging = () =>
    this.getWaardelijst(REDENINTREKKINGVERVANGING, this.wlFactory.createRedenLijst().at(0)?.children);
  public getRonlOnderwerpen = () =>
    this.getWaardelijst(
      ONDERWERPEN_RONL,
      this.wlFactory.createFromTooi(require("../assets/waardelijsten/onderwerpen_ronl.json"))
    );
  public getMinisterie = () => this.getWaardelijst(MINISTERIE, this.wlFactory.createMinisterieLijst());
  public getTaal = () => this.getWaardelijst(TAAL, this.wlFactory.createTaalLijst());
  public getInformatiecategorieen = () =>
    this.getWaardelijst(INFORMATIECATEGORIEEN, this.wlFactory.createInformatiecategorieenLijst());
}

import { DefaultLayout } from "../../layouts";
import {
  LoginFormOrganism,
  OverheidHeaderOrganism,
  ToastOrganism
} from "../../organisms";


export default function LoginScreen() {
  return (
    <DefaultLayout
      header={<>
        <ToastOrganism />
        <OverheidHeaderOrganism />
      </>
      }
      body={<LoginFormOrganism />}
    />
  );
}

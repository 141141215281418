import { Classificatiecollectie, Metadata, Identifier, Titelcollectie } from "../models";
import { Documenthandelingen, Documentrelaties, Geldigheid } from "../models/metadata";

export class MetadataFactory {
    public create = (

        publisher: Identifier,
        identifiers: string[],
        language: Identifier,
        titelcollectie: Titelcollectie,
        classificatiecollectie: Classificatiecollectie,
        documenthandelingen: Documenthandelingen[],
        publicatiebestemming: string[],

        creatiedatum?: string,
        verantwoordelijke?: Identifier,
        naamOpsteller?: string,
        omschrijvingen?: string[],
        format?: Identifier,
        onderwerpen?: string[],
        aggregatiekenmerk?: string,
        documentrelaties?: Documentrelaties[],
        geldigheid?: Geldigheid,
        pid?: string,

    ): Metadata => {
        return {
            document: {
                publisher,
                identifiers,
                language,
                titelcollectie,
                classificatiecollectie,
                documenthandelingen,
                publicatiebestemming,

                creatiedatum,
                verantwoordelijke,
                naamOpsteller,
                omschrijvingen,
                format,
                onderwerpen,
                aggregatiekenmerk,
                geldigheid,
                pid,
            },
             documentrelaties: documentrelaties ?? []
        }
    };
}
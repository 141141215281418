import { IconAtom, ToastAtom } from "../../atoms";
import { ToastType } from "../../interfaces/Toast";
import styles from "./styles.module.scss";

interface Props {
  message: string;
  type: ToastType;
  onClose?: () => void;
}

export function ToastMolecule(props: Props) {
  return (
    <ToastAtom type={props.type}>
      <div onClick={() => (props.onClose ? props.onClose() : {})} className={styles.right}>
        <IconAtom icon={"icon-close-hover"} size={"medium"} alt={"close"} />
      </div>
      <div className={styles.toast}>
        <p>{props.message}</p>
      </div>
    </ToastAtom>
  );
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiFactory } from "../../api";
import { GebruikersInfo } from "../../models";
import { IdentifierMetAfkorting } from "../../models/identifier";
import { Storage, StorageLocation } from "../../utils/Storage";
import {
  WaardelijstCrawler
} from "../../utils/WaardelijstCrawler";

interface AuthState {
  isAuthenticated: boolean;
  fetching: boolean;
  organisatie: IdentifierMetAfkorting[];
  lidmaatschappen: IdentifierMetAfkorting[];
  expiration: string | undefined;
  roles: string[];
  error?: string;
}

const initialState: AuthState = {
  fetching: false,
  isAuthenticated: false,
  organisatie: [],
  expiration: undefined,
  lidmaatschappen: [],
  roles: [],
};

export const redirectLogin = createAsyncThunk(
  "auth/redirectLogin",
  async () => {
    Storage.Clear(StorageLocation.TOKEN);
    ApiFactory.createAuthApi().Login();
  }
);

export const getUserInfo = createAsyncThunk(
  "auth/userInfo",
  async (_, thunkAPI) => {
    const userInfo = await ApiFactory.createAuthApi()
      .GetUserInfo()
      .catch((error: any) => {
        throw error;
      });
    thunkAPI.dispatch(_setUserInfo(userInfo));
  }
);

export const login = createAsyncThunk("auth/login", async (_, thunkAPI) => {
  ApiFactory.createAuthApi().Login();
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await ApiFactory.createAuthApi().Logout();
});

const authState = createSlice({
  name: "auth",
  initialState,
  reducers: {
    _setUserInfo: (state, action: PayloadAction<GebruikersInfo>) => {
      state.isAuthenticated = true;
      const org = WaardelijstCrawler.searchOrganisationLabel(action.payload.organisatie) as IdentifierMetAfkorting;
      org.afkorting = WaardelijstCrawler.searchOrganisationAfkorting(org).afkorting;
      state.organisatie = [org];
      const lid = action.payload.lidmaatschappen.map((mem: any) => WaardelijstCrawler.searchOrganisationLabel(mem)) as IdentifierMetAfkorting[];
      lid.forEach(
        (member: IdentifierMetAfkorting) =>
          (member.afkorting = WaardelijstCrawler.searchOrganisationAfkorting(member).afkorting)
      );
      state.lidmaatschappen = lid;
      const now = new Date();
      now.setHours(now.getHours() + 8);
      state.expiration = now.toString();
    },
  },
  extraReducers: (caseBuilder) => {
    caseBuilder.addCase(
      login.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetching = false;
      }
    );
    caseBuilder.addCase(logout.fulfilled, (state, action) => {
      state.fetching = false;
      state.isAuthenticated = false;
      state.organisatie = [];
      state.expiration = undefined;
      state.lidmaatschappen = [];
      state.roles = [];
    });
  },
});

const { _setUserInfo } = authState.actions;
export default authState.reducer;

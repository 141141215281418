import { DocumentDetails, Documentrelaties } from "./metadata";

interface Link {
    href: string;
    type: string;
}

interface ResultMeta {
    metadata: Link;
    document: DocumentDetails;
}

export interface SelectedRow {
    index: number;
    foldableContent: SearchResult[];
}

export interface Result {
    documentsoort: string,
    identifiers: string[],
    officieleTitel: string,
    openbaarmakingsdatum: string,
    mutatiedatumtijd: string,
    _links: ResultMeta;
}

export interface SearchResults {
    totaal: number;
    start: number,
    aantal: number;
    resultaten: SearchResult[];
}

interface DocVersion {
    "nummer": number,
    "oorzaak": string,
    "redenVerwijderingVervanging": string,
    "openbaarmakingsdatum": string,
    "mutatiedatumtijd": string
}

interface PlooiIntern {
    "dcnId": string,
    "aanbieder": string,
    "extId": string[],
    "sourceLabel": string;
    "publicatiestatus": string;
}

export interface SearchResult {
    document: DocumentDetails;
    documentrelaties?: Documentrelaties[];
    versies: DocVersion[];
    plooiIntern: PlooiIntern;
}

// Bij de search is ander response dan bij de metadata
export const PUBLICATIE_STATUS_SEARCH = {
    uitgesteld: "_latere-publicatie",
    gepubliceerd: "gepubliceerd",
    ingetrokken: "_intrekking",
    virusscanner: "_virusscan",
    virus: "virus",
    mappingfouten: "mappingfouten"
  };
  
import { isArray } from "redoc";
import styles from "./styles.module.scss";

interface Props {
  id: string;
  children: string | string[];
  for?: string;
  type: "default" | "blue" | "primary" | "white" | "orange" | "success" | "warning" | "danger" | "large" | "form";

  bold?: boolean;
  underlined?: boolean;
  italic?: boolean;
}

export function LabelAtom(props: Props) {
  const styleType = styles[`label--${props.type}`];
  let className = `${styles.label} ${styleType}`;

  if (props.bold) {
    const styleBold = styles[`label--bold`];
    className += ` ${styleBold}`;
  }

  if (props.underlined) {
    const styleUnderlined = styles[`label--underlined`];
    className += ` ${styleUnderlined}`;
  }

  if (props.italic) {
    const styleItalic = styles[`label--italic`];
    className += ` ${styleItalic}`;
  }

  return (
    <label htmlFor={props.for} className={className}>
      {Array.isArray(props.children)
        ? props.children.map((child: string, index: number) => (
            <p key={isArray(props.children) && props.children.length > 1 ? props.id + "_" + index : props.id}>
              {child}
            </p>
          ))
        : props.children}
    </label>
  );
}

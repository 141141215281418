import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getUserInfo } from '../store/reducers/auth';
import { getMeldingen } from '../store/reducers/meldingen';
import { selectAuth } from '../store/selectors';
import {RedocScreen} from "../ui/screens";

const LoginScreen = React.lazy(() => import('../ui/screens/login'));
const StartScreen = React.lazy(() => import('../ui/screens/start'));
const DocumentenScreen = React.lazy(() => import('../ui/screens/documenten'));
const AanleverenScreen = React.lazy(() => import('../ui/screens/create'));
const OverScreen = React.lazy(() => import('../ui/screens/over'));

function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route
        path='*'
        element={
          /// ---------------------- /over in url zorgt voor problemen
          <React.Suspense fallback={<>...</>}>
            <LoginScreen />
          </React.Suspense>
        }
      ></Route>
      <Route
        path='/over'
        element={
          <React.Suspense fallback={<>...</>}>
            <OverScreen />
          </React.Suspense>
        }
      ></Route>
        <Route
            path='/api-doc'
            element={
                <React.Suspense fallback={<>...</>}>
                    <RedocScreen />
                </React.Suspense>
            }
        ></Route>
    </Routes>
  );
}

function AuthenticatedRoutes() {
  return (
    <Routes>
      <Route
        path='/start'
        element={
          <React.Suspense fallback={<>...</>}>
            <StartScreen />
          </React.Suspense>
        }
      ></Route>
      <Route
        path='/documenten'
        element={
          <React.Suspense fallback={<>...</>}>
            <DocumentenScreen />
          </React.Suspense>
        }
      ></Route>
      <Route
        path='/aanleveren/:id'
        element={
          <React.Suspense fallback={<>...</>}>
            <AanleverenScreen />
          </React.Suspense>
        }
      ></Route>
      <Route
        path='/aanleveren'
        element={
          <React.Suspense fallback={<>...</>}>
            <AanleverenScreen />
          </React.Suspense>
        }
      ></Route>
      <Route
        path='/update'
        element={
          <React.Suspense fallback={<>...</>}>
            <AanleverenScreen />
          </React.Suspense>
        }
      ></Route>
      <Route
        path='/over'
        element={
          <React.Suspense fallback={<>...</>}>
            <OverScreen />
          </React.Suspense>
        }
      ></Route>
        <Route
            path='/api-doc'
            element={
                <React.Suspense fallback={<>...</>}>
                    <RedocScreen />
                </React.Suspense>
            }
        ></Route>
      <Route
        path='*'
        element={
          <React.Suspense fallback={<>...</>}>
            <StartScreen />
          </React.Suspense>
        }
      ></Route>
    </Routes>
  );
}

export default function RootRouter() {
  const { isAuthenticated } = useAppSelector(selectAuth);

  const dispatch = useAppDispatch();

  const authRoutes = AuthenticatedRoutes();
  const unauthRoutes = UnauthenticatedRoutes();

  React.useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getMeldingen());
  }, [dispatch]);

  return (
    <Router>
      {isAuthenticated && authRoutes}
      {!isAuthenticated && unauthRoutes}
    </Router>
  );
}

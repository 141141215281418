import { IListValidator, ITextValidator } from "../models/validator";

export class MinimumLengthValidator implements ITextValidator {
    private _minLength = 0;

    constructor(length: number) {
        this._minLength = length;
    }

    validate(value?: string): string | undefined {
        const msg = `Een minimum van ${this._minLength} karakters is vereist.`;
        const isEmpty = !value;

        if (isEmpty || value.length >= this._minLength)
            return;

        return msg;
    }
}

export class MinimumSelectedValidator implements IListValidator {
    private _minLength = 0;

    constructor(length: number) {
        this._minLength = length;
    }

    validate(values?: any[]): string | undefined {
        if (!values || !values.length || values.length < this._minLength)
            return `Selecteers minstens ${this._minLength} items(s).`;
    }

}
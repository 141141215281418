import { Metadata } from "../models";
import { PUBLICATIE_STATUS } from "../models/metadata";

export const isPublished = (meta: Metadata): boolean => {
    const {
        document: { pid }
    } = meta;

    if (!pid)
        return false;
        
    return matchStatus(meta, PUBLICATIE_STATUS.gepubliceerd);

}

export const matchStatus = (meta: Metadata, status: string) => {
    return (meta.plooiIntern && meta.plooiIntern?.publicatiestatus.indexOf(status) > -1) ? true : false;}
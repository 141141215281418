import { useState } from "react";
import { IconLabelMolecule, LabelMolecule } from "..";
import { IFileValidator } from "../../../models/validator";
import { ContainerAtom, FileInputAtom, HeadingPreviewAtom, LabelAtom } from "../../atoms";
import styles from "./styles.module.scss";

interface Props {
  onSelectFile: (file?: File) => void;
  selectedFileName?: string;
  selectedInput?: File | string;
  label?: string;
  tooltip?: string;
  required?: boolean;
  validations?: IFileValidator[];
  inputRef?: (ref: HTMLDivElement) => void;
  disabled?: boolean;
}

export function FileInputMolecule(props: Props) {
  const [error, setError] = useState<string>();

  let className = styles.input_container__inner;
  if (error) className += ` ${styles["input_container__inner--alert"]}`;

  let inputClassName = styles.input_container;
  if (props.disabled) inputClassName += ` ${styles["input_container--disabled"]}`;

  const onFileChanged = (file?: File) => {
    const errors = props.validations?.map((validation: IFileValidator) => validation.validate(file));

    if (errors?.some((errorMessage: string | undefined) => errorMessage !== undefined))
      setError(errors.filter((errorMessage: string | undefined) => errorMessage)[0]);
    else {
      setError(undefined);
      props.onSelectFile(file);
    }
  };

  const onClickFileInput = (file?: File | string) => {
    if (typeof file !== "string" && !(file instanceof String)) onFileChanged(file);
  };

  if (props.disabled && !props.selectedFileName) {
    return <></>;
  }

  return (
    <div>
      <LabelMolecule
        id={"file_input_label"}
        label={props.label ?? ""}
        tooltip={props.tooltip}
        required={props.required}
      />
      <FileInputAtom
        disabled={props.disabled}
        accept={["ZIP", "PDF"]}
        onFileChanged={onFileChanged}
        onClickFileInput={onClickFileInput}
        inputRef={props.inputRef}
        selectedInput={props.selectedInput}
      >
        <div className={inputClassName}>
          {props.selectedFileName && (
            <div className={styles.input_container__selectedFileContainer}>
              <HeadingPreviewAtom id={"file_input_title"}>{props.selectedFileName}</HeadingPreviewAtom>
              {!props.disabled && (
                <LabelAtom id={"selecteer_ander_bestand"} italic underlined type="form">
                  Selecteer een ander bestand
                </LabelAtom>
              )}
            </div>
          )}
          {!props.selectedFileName && !props.disabled && (
            <div className={className}>
              <ContainerAtom>
                <LabelAtom id={"upload_nieuw_bestand"} italic type="form">
                  Upload een nieuw bestand:
                </LabelAtom>
                <LabelAtom id={"selecteer_bestand"} italic underlined type="form">
                  Selecteer een bestand
                </LabelAtom>
              </ContainerAtom>
            </div>
          )}
        </div>
      </FileInputAtom>
      {error && <IconLabelMolecule icon={"icon-alert-red"} label={error} type={"alert"} />}
    </div>
  );
}

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  AuthReducer,
  MeldingenReducer,
  MetadataReducer,
  SearchReducer,
  ToastReducer,
} from "./reducers";

const rootReducer = combineReducers({
  auth: AuthReducer,
  melding: MeldingenReducer,
  toast: ToastReducer,
  meta: MetadataReducer,
  search: SearchReducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

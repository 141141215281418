import styles from "./styles.module.scss";

interface DefaultProps {
  id: string;
  onClick: () => void;
  children: any;
}

interface Props extends DefaultProps {
  type?: "default" | "primary" | "unstyled" | "orange" | "blue";
  disabled?: boolean;
  title?: string;
}

export function ButtonAtom(props: Props) {
  let className: string = styles.button;

  if (props.type && props.type !== "default") {
    const style = styles[`button--${props.type}`];
    className += ` ${style}`;
  }

  return (
    <button
      id={props.id}
      type="button"
      disabled={props.disabled}
      className={className}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  );
}

export function ButtonLookALikeAtom(props: Readonly<DefaultProps>) {
  return (
    <button id={props.id} className={styles.lookalike} onClick={props.onClick}>
      {props.children}
    </button>
  );
}

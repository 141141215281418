import { Documentrelaties } from '../models/metadata';

export const HEEFT_BIJLAGE = "https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3";
export const BIJLAGE_VAN = "https://identifier.overheid.nl/tooi/def/thes/kern/c_4d1ea9ba";

export const isBijlageDocument = (relaties: Documentrelaties[]): boolean => {
    if (!relaties || relaties.length === 0)
        return false;

    return (relaties.some((relatie:Documentrelaties) => relatie.role === BIJLAGE_VAN));
}
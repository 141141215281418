import { getSublistsOptions } from "../../../utils/FilterList";
import {
  CheckboxAtom,
  LabelAtom,
  ListAtom,
  ListItemAtom,
  SpacerAtom
} from "../../atoms";
import { ICategoryList } from "../../interfaces/CategoryList";
import { ICheckbox } from "../../interfaces/Checkbox";
import styles from "./styles.module.scss";

interface Props {
  list: ICategoryList[];
  onClick: (cb: ICheckbox) => void
}

export function CheckboxListMolecule(props: Props) {
  return <>{
    props.list.map((categoryList:ICategoryList, index:number) => <div key={"div_" + categoryList.title}>
      {index !== 0 && <SpacerAtom space={4} />}
      {getSublistsOptions(categoryList).map((cl:ICategoryList) => {
        return <div key={"subList_" + cl.title} className={styles.sub}>
          <div className={styles.list}>
            {cl.title && (
              <>
                <LabelAtom id={cl.title} type={"form"} bold>
                  {cl.title}
                </LabelAtom>
                <SpacerAtom space={2} />
              </>
            )}

            <ListAtom unstyled>{cl.options.map((option:ICheckbox) => {
              return (
                <ListItemAtom key={option.id} bold={option.bold}>
                  <CheckboxAtom {...option} onClick={() => props.onClick(option)} />
                </ListItemAtom>
              );
            })}</ListAtom>
          </div>
          <SpacerAtom space={2} />
        </div>;
      })}
    </div>)
  }</>;
}
import "../../columns.scss";
import styles from "./styles.module.scss";

interface Props {
  children?: any;
  width?: number | string;
  height?: number | string;
  type?: "columns" | "row" | "flex" | "between" | "grid";
  centeredHorizontal?: boolean;
  centeredVertical?: boolean;
}

export function ContainerAtom(props: Props) {
  let className = styles.container;

  if (props.centeredHorizontal) className += ` ${styles["container--centered-horizontal"]}`;

  if (props.centeredVertical) className += ` ${styles["container--centered-vertical"]}`;

  if (props.type === "columns") className += " columns";

  if (props.type === "flex") className += ` ${styles["container--flex"]}`;

  if (props.type === "row") className += ` ${styles["container--row"]}`;

  if (props.type === "between") className += ` ${styles["container--between"]}`;

  if (props.type === "grid") className += ` ${styles["container--grid"]}`;

  return (
    <div
      className={className}
      style={{
        width: props.width,
        height: props.height,
      }}
    >
      {props.children}
    </div>
  );
}

import React from "react";
import { HeadingH2Atom } from "../../atoms";
import styles from "./styles.module.scss";

interface Props {
  subheader?: string;
  header: string;
  intro: string[] | string | JSX.Element;
  link?: any;
  center?: boolean;
  type?: 'normal' | 'warning';
}

export function JumbotronMolecule(props: Props) {
  let className = styles.container;
  if (props.center) {
    const styleCenter = styles[`center`];
    className += ` ${styleCenter}`;
  }
  if (props.type && props.type !== 'normal') {
    const styleCenter = styles[props.type];
    className += ` ${styleCenter}`;
  }

  let intro: JSX.Element | JSX.Element[];

  if(Array.isArray(props.intro)) {
    intro = (props.intro.map((text:string, index:number) => <p key={"hulp_contact_" + index} >{text}</p>))
  } else if(React.isValidElement(props.intro)){
    intro = props.intro;
  } else {
    intro = (<p>{props.intro}</p>);
  } 

  return (
    <div className={className}>
      {props.subheader && <i>{props.subheader}</i>}
      <HeadingH2Atom>{props.header}</HeadingH2Atom>
      {intro}
      {props.link && <u>{props.link}</u>}
    </div>
  );
}

import styles from "./styles.module.scss";

interface Props {
  children?: string;
}

export function BannerStrokeAtom(props: Props) {

  return <div className={styles.stroke}>{props.children}</div>;
}

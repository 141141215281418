import { FocusEvent, KeyboardEvent } from "react";
import { stringToDate } from "../../../utils/DateFormatter";
import styles from "./styles.module.scss";

interface Props {
  id?: string;
  inputRef?: (ref: HTMLInputElement) => void;
  placeholder?: string;
  onFocus?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  onEnter?: () => void;
  onEscape?: () => void;
  value?: string;
  defaultValue?: string;
  type: "text" | "email" | "password" | "numeric" | "date" | "time";
  minDate?: Date;
  disabled?: boolean;
  error?: boolean;
}

export function TextInputAtom(props: Props) {
  let className = `${styles["input--text"]}`;

  if (props.error) className += ` ${styles["input--alert"]}`

  const onKeydown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") props.onEnter && props.onEnter();

    if (ev.key === "Escape") props.onEscape && props.onEscape();
  };

  return (
    <input
      ref={(ref:HTMLInputElement | null) => {
        props.inputRef && ref && props.inputRef(ref);
      }}
      disabled={props.disabled}
      onChange={(ev:React.ChangeEvent<HTMLInputElement>) => {
        if (props.type === 'date' && props.minDate && ev.currentTarget.value) {
          const date = stringToDate(ev.currentTarget.value).getTime();
          const minValue = props.minDate.getTime();

          if (date < minValue) {
            props.onChange && props.onChange("");
            return;
          }
        }
        props.onChange && props.onChange(ev.currentTarget.value);
      }}
      onKeyDown={onKeydown}
      value={props.value}
      defaultValue={props.defaultValue}
      type={props.type}
      id={props.id}
      name={props.id}
      className={className}
      placeholder={props.placeholder}
      aria-invalid="false"
      onFocus={props.onFocus}
      onBlur={(value:React.ChangeEvent<HTMLInputElement>) => props.onBlur!(value)}
    />
  );
}

import { Controllers } from "../models";

export class ResponseCodesFactory {
    public create(controller: Controllers, responseCode: number): string {
        switch (controller) {
            case "logout":
                return DefaultResponseCodeFactory.create(responseCode);
                
            case "metadata":
                return DefaultResponseCodeFactory.create(responseCode);

            case "documenten":
                return DocumentenResponseCodeFactory.create(responseCode);

            case "_zoek":
                return DefaultResponseCodeFactory.create(responseCode);
            
            case "gebruikersinfo":
                return DefaultResponseCodeFactory.create(responseCode);
            
            default:
                throw DefaultResponse(responseCode);
        }
    }
}

class DefaultResponseCodeFactory {
    static create(responseCode: number): string {
        return DefaultResponse(responseCode);
    }
}

class DocumentenResponseCodeFactory {
    static create(responseCode: number): string {
        switch (responseCode) {
            case 400:
                return 'Het bestand kon niet verwerkt worden';
            case 401:
                return 'Deze handeling is alleen mogelijk voor geautoriseerden';
            case 403:
                return 'U bent niet geautoriseerd voor deze actie';
            case 404:
                return 'De opgevraagde resource bestaat niet';
            case 413:
                return 'Het bestand is te groot om verwerkt te kunnen worden';
            case 415:
                return 'Formaat niet ondersteund';
            case 418:
                return 'Deze bewerking kon niet worden voltooid. Controleer uw aanlevering(-en) voor u de werkzaamheden hervat (Netwerkproblemen).';
            case 500:
                return "Deze bewerking kon niet worden voltooid. Controleer uw aanlevering(-en) voor u de werkzaamheden hervat (500).";
            case 504:
                return "Deze bewerking kon niet worden voltooid. Controleer uw aanlevering(-en) voor u de werkzaamheden hervat (504).";
            default:
                return "Er is een fout opgetreden";
        }
    }
}

const DefaultResponse = (responseCode: number): string => {
    switch (responseCode) {
        case 400:
            return 'De aangeleverde metadata voldoen niet aan het schema';
        case 401:
            return 'Deze handeling is alleen mogelijk voor geautoriseerden';
        case 403:
            return 'U bent niet geautoriseerd voor deze actie';
        case 404:
            return 'De opgevraagde resource bestaat niet';
        case 418:
            return 'Deze bewerking kon niet worden voltooid. Controleer uw aanlevering(-en) voor u de werkzaamheden hervat (Netwerkproblemen).';
        case 500:
            return "Deze bewerking kon niet worden voltooid. Controleer uw aanlevering(-en) voor u de werkzaamheden hervat (500).";
        case 504:
            return "Deze bewerking kon niet worden voltooid. Controleer uw aanlevering(-en) voor u de werkzaamheden hervat (504).";
        default:
            return "Er is een fout opgetreden";
    }
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiFactory } from "../../api";
import { Melding } from "../../models/melding";

interface MeldingenState {
  meldingen: Melding[];
}

const initialState: MeldingenState = {
  meldingen: []
};

export const getMeldingen = createAsyncThunk(
  'meldingen/get',
  async (_, thunkAPI) => {
    const melding = await ApiFactory.createMeldingenApi()
      .GetMeldingen()
      .catch((error: any) => {
        throw error;
      });
    thunkAPI.dispatch(_setMeldingen(melding));
  }
);

const meldingState = createSlice({
  name: 'meldingen',
  initialState,
  reducers: {
    _setMeldingen: (state, action: PayloadAction<Melding[]>) => {
      state.meldingen = action.payload;
    }
  },
});

const { _setMeldingen } = meldingState.actions;
export default meldingState.reducer;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { IdentifierWithChildren, WaardelijstFactory } from "../../../factories/waardelijst";
import { IdentifierMetAfkorting } from "../../../models/identifier";
import { Documentrelaties, PUBLICATIE_STATUS } from "../../../models/metadata";
import { SearchResult, SelectedRow } from "../../../models/search-result";
import { Sorting } from "../../../models/sorting";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { conceptDate, publiceerConcept } from "../../../store/reducers/metadata";
import {
  clearRowsUnfolded,
  clearSearch,
  clearSelectedConcepts,
  searchBijlages,
  searchDocuments,
  updateFilter,
  updateSearch,
  updateSelectedConcepts,
  updateSorting,
  updateSortingOrder,
} from "../../../store/reducers/search";
import { selectSearch } from "../../../store/selectors";
import { formatStringToDate } from "../../../utils/DateFormatter";
import { matchStatus } from "../../../utils/Metadata";
import { HEEFT_BIJLAGE, isBijlageDocument } from "../../../utils/Relaties";
import { StringSanitizer } from "../../../utils/StringSanitizer";
import { WaardelijstCrawler } from "../../../utils/WaardelijstCrawler";
import {
  CheckboxAtom,
  ContainerAtom,
  HeadingH2Atom,
  IconAtom,
  SpacerAtom,
  SpinnerAtom,
  WindowTableAtom,
} from "../../atoms";
import { ITableRow } from "../../interfaces/TableRows";
import { IToggle } from "../../interfaces/Toggle";
import { ButtonMolecule, IconButtonMolecule, SearchInputMolecule, ToggleRowMolecule } from "../../molecules";

const stringSanitizer = new StringSanitizer();

export function DocumentenLijstOrganism() {
  const stateSearch = useAppSelector(selectSearch);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const waardelijstFactory = new WaardelijstFactory();
  const [ministeries, setMinisteries] = useState<IdentifierMetAfkorting[]>([]);

  const searchNext = async () => {
    if (stateSearch.fetching) return;

    if (stateSearch.data.resultaten.length < stateSearch.data.totaal) await dispatch(searchDocuments());
  };

  useEffect(() => {
    updateList();
    const waardelijstMinisteries = waardelijstFactory.createMinisterieLijst(false);
    const ministeriesMetAfkorting: IdentifierMetAfkorting[] = waardelijstMinisteries.map(
      (ministeries: IdentifierWithChildren) => {
        const afkorting: IdentifierMetAfkorting = WaardelijstCrawler.searchOrganisationAfkorting({
          id: ministeries.id,
          label: ministeries.label,
        });
        return {
          id: ministeries.id,
          label: ministeries.label,
          afkorting: afkorting.afkorting ?? undefined,
        };
      }
    );
    setMinisteries(ministeriesMetAfkorting);
  }, [dispatch]);

  const navigateToUpdate = async (id: string) => {
    navigate(`/aanleveren/${id}`);
  };

  const mapNumberToSorting = (index: number): Sorting | undefined => {
    switch (index) {
      case 1:
        return "titel";
      case 2:
        return "laatst-bewerkt";
      case 3:
        return "gepubliceerd-vanaf";
      case 4:
        return "organisatie";
      default:
        return undefined;
    }
  };

  const mapSortingToNumber = (sorting: Sorting): number | undefined => {
    switch (sorting) {
      case "titel":
        return 1;
      case "laatst-bewerkt":
        return 2;
      case "gepubliceerd-vanaf":
        return 3;
      case "organisatie":
        return 4;
      default:
        return undefined;
    }
  };

  const onClickTableHeader = async (index: number) => {
    const sorting = mapNumberToSorting(index);

    if (
      ((sorting && index <= 3 && stateSearch.filters !== "concept") ||
        (sorting && index >= 1 && index <= 4 && stateSearch.filters === "concept")) &&
      sorting !== stateSearch.sorting
    ) {
      dispatch(updateSorting(sorting));
      await updateList();
    }

    if (sorting === stateSearch.sorting) {
      dispatch(updateSortingOrder(stateSearch.sortingOrder !== "desc" ? "desc" : "asc"));
      await updateList();
    }
  };

  const updateList = async () => {
    dispatch(clearSelectedConcepts());
    dispatch(clearSearch());
    await dispatch(searchDocuments());
  };

  const leadingIconSetje = (index: number) => {
    const rowSelected = stateSearch.rowsUnfolded.find((row: SelectedRow) => row.index === index);
    const icon = rowSelected ? "icon-dart-down" : "icon-arrow-right";

    return <IconAtom icon={icon} size={"medium"} alt={"Heeft Bijlagen"} type={".svg"} />;
  };

  const getSearchResultVariables = (
    result: SearchResult
  ): {
    org: any;
    title: any;
    gepubliceerdVanaf: any;
    isConcept: any;
    status: any;
  } => {
    const org = ministeries.find(
      (organisatie: IdentifierMetAfkorting) => organisatie.label === result.document.publisher.label
    )?.afkorting;
    const title = result.document.titelcollectie.officieleTitel;
    const gepubliceerdVanaf =
      matchStatus(result, PUBLICATIE_STATUS.uitgesteld) && result.document.zichtbaarheidsdatumtijd
        ? result.document.zichtbaarheidsdatumtijd
        : result.versies[0].openbaarmakingsdatum;
    const isConcept = new Date(gepubliceerdVanaf ?? "").toJSON() === conceptDate.toJSON();
    const status = isConcept
      ? "Concept"
      : stringSanitizer.sanitizePublicatieStatus(result.plooiIntern.publicatiestatus ?? "");
    return { org, title, gepubliceerdVanaf, isConcept, status };
  };

  const styleTitle: React.CSSProperties = {
    wordWrap: "break-word",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    margin: "0.7em",
    overflow: "hidden",
    textAlign: "left",
    textOverflow: "ellipsis",
  };

  const heeftBijlage = (result: SearchResult): Documentrelaties | undefined => {
    return result.documentrelaties?.find((documentrelatie: Documentrelaties) => documentrelatie.role === HEEFT_BIJLAGE);
  };

  const leadingIcon = (result: SearchResult, index: number): JSX.Element | undefined => {
    if (stateSearch.filters !== undefined) {
      return heeftBijlage(result) ? leadingIconSetje(index) : undefined;
    } else if (isBijlageDocument(result.documentrelaties!))
      return <IconAtom icon={"paper-clip-blue"} size={"medium"} alt={"Bijlagen"} type={".png"} />;
    else return undefined;
  };

  const mapToRow = (result: SearchResult, index: number): (string | string[] | JSX.Element | undefined)[] => {
    const { org, title, gepubliceerdVanaf, isConcept, status } = getSearchResultVariables(result);
    const typeIcon = leadingIcon(result, index);

    return [
      stateSearch.filters === "concept" ? (
        <CheckboxAtom
          id={"concept_checkbox_" + index}
          checked={stateSearch.selectedConcepts.includes(result)}
          label={""}
          onClick={() => {
            dispatch(updateSelectedConcepts(result));
          }}
        />
      ) : undefined,
      <ContainerAtom key={"title_" + index} type="grid" centeredVertical>
        {typeIcon ?? <SpacerAtom space={"18px"} type="horizontal" />}
        <div style={styleTitle}>{title}</div>
      </ContainerAtom>,
      formatStringToDate(result.versies[0].mutatiedatumtijd),
      isConcept ? "" : formatStringToDate(gepubliceerdVanaf),
      org,
      result.document.identifiers,
      status,
      <IconButtonMolecule
        key={index + "_2"}
        icon={"Bewerken"}
        size={"medium"}
        id={`document_${index + 1}_bewerken`}
        title="Pas de gegevens van het gepubliceerde document aan."
        onClick={() => navigateToUpdate(stringSanitizer.extractIdentifierFromPid(result.document.pid))}
        type={"default"}
        square
      />,
    ];
  };

  const bijlagenRow = (index: number): (string | string[] | JSX.Element | undefined)[][] | undefined => {
    return stateSearch.rowsUnfolded
      .find((row: SelectedRow) => row.index === index)
      ?.foldableContent?.map((result1, index1) => {
        let row = mapToRow(result1, index1);
        row[0] = undefined;
        return row;
      });
  };

  const tableRowContent = (originalValue: any, title: string, index: number) => {
    return stateSearch.fetchingBijlage &&
      stateSearch.rowsUnfolded.find((row: SelectedRow) => row.index === index && row.foldableContent.length === 0) ? (
      <ContainerAtom type="grid" centeredVertical>
        {<SpinnerAtom type={"primary"} size={"extraSmall"} />}
        <div>{title}</div>
      </ContainerAtom>
    ) : (
      originalValue
    );
  };

  let rows = !stateSearch.data
    ? []
    : stateSearch.data.resultaten.map((result: SearchResult, index: number) => {
        const content = mapToRow(result, index);
        let tableRow = { content } as ITableRow;

        if (heeftBijlage(result) && stateSearch.filters !== undefined) {
          const uuid = stringSanitizer.extractIdentifierFromPid(result.document.pid);
          const bijlageRow = bijlagenRow(index);
          const originalValue = tableRow.content[1];
          const title = result.document.titelcollectie.officieleTitel;
          tableRow.content[1] = tableRowContent(originalValue, title, index);
          tableRow = {
            ...tableRow,
            onClick: () => {
              if (!stateSearch.fetchingBijlage) dispatch(searchBijlages({ uuid: uuid, rowIndex: index }));
            },
            foldableContent: { content: bijlageRow } as ITableRow,
          };
        }

        return tableRow;
      });

  const onUpdateSearch = (search: string) => {
    const filtered = search.replace(/[^a-zA-Z0-9À-ž ,.:;"'?-]/g, "");
    dispatch(updateSearch(filtered));
  };

  const toggles = [
    {
      id: "Mixed",
      icon: "icon-hamburger",
      iconChecked: "icon-hamburger-white",
      checked: stateSearch.filters === undefined,
      onClick: () => {
        dispatch(updateFilter(undefined));
        updateList();
      },
    } as IToggle,
    {
      id: "Setjes",
      icon: "icon-list",
      iconChecked: "icon-list-white",
      checked: stateSearch.filters === "hoofddocument",
      onClick: () => {
        if (stateSearch.search === "") {
          dispatch(updateFilter("hoofddocument"));
          updateList();
        }
      },
    } as IToggle,
    {
      id: "Concepten",
      icon: "Bewerken",
      iconChecked: "Bewerken-white",
      checked: stateSearch.filters === "concept",
      onClick: () => {
        if (stateSearch.search === "") {
          dispatch(updateFilter("concept"));
          updateList();
        }
      },
    } as IToggle,
  ];

  return (
    <>
      <ContainerAtom type="row">
        <ContainerAtom type="between">
          <HeadingH2Atom>Documentenlijst</HeadingH2Atom>
          {stateSearch.selectedConcepts.length > 0 && (
            <ButtonMolecule
              text={"Publiceren"}
              id={"publiceren"}
              title={"Publiceer concepten."}
              type={"primary"}
              onClick={async () => {
                await dispatch(publiceerConcept(stateSearch.selectedConcepts));
                updateList();
              }}
            />
          )}
        </ContainerAtom>
        <ContainerAtom type="grid">
          <SearchInputMolecule
            value={stateSearch.search}
            onSubmit={() => {
              dispatch(updateFilter(undefined));
              updateList();
            }}
            onChange={onUpdateSearch}
          />
          <ToggleRowMolecule id={"lijstOptions"} toggles={toggles} startIndex={0} />
        </ContainerAtom>
      </ContainerAtom>
      <SpacerAtom space={4} />
      <WindowTableAtom
        theadsHighlightIndex={mapSortingToNumber(stateSearch.sorting)}
        theadsHighlighticon={
          stateSearch.sortingOrder === "desc" ? "icon-arrow-down-white.svg" : "icon-arrow-up-white.svg"
        }
        OnClickTheads={(index: number) => {
          onClickTableHeader(index);
          dispatch(clearRowsUnfolded());
        }}
        theads={[
          <></>,
          <>Titel</>,
          <>Laatst bewerkt</>,
          <>Gepubliceerd vanaf</>,
          <>Org.</>,
          <>Identificatiekenmerk</>,
          <>Status</>,
          "",
        ]}
        rows={rows}
        selectedRows={stateSearch.filters !== undefined ? stateSearch.rowsUnfolded : []}
        columnWidth={
          stateSearch.filters === "concept"
            ? ["4.4%", "35.6%", "10%", "10%", "7%", "18%", "11.5%", "5%"]
            : ["0%", "35%", "10%", "10%", "7%", "18%", "11.5%", "5%"]
        }
        OnEndOfScroll={searchNext}
      />
    </>
  );
}

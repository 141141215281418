import { Controllers, Identifier } from "../models";
import { PUBLICATIE_STATUS } from "../models/metadata";

export const ensureSlash = (value: string) => value.charAt(value.length - 1) === '/' ? value : `${value}/`;

export class StringSanitizer {
    private ensureNoSlash = (value: string) => {
        const firstChar = value.charAt(0);
        const lastChar = value.charAt(value.length - 1);

        if (firstChar === '/' && lastChar === '/')
            return value.substring(1, value.length - 1);


        if (firstChar === '/')
            return value.substring(1);


        if (lastChar === '/')
            return value.substring(0, value.length - 1);

        return value;
    }

    public removeExtension = (fileName: string) => {
        const parts = fileName.split('.');
        parts.pop();

        return parts.join(' ');
    }

    public sanitizePublicatieStatus = (value: string) => {
        if (value.indexOf(PUBLICATIE_STATUS.ingetrokken) > -1)
            return "Ingetrokken";

        if (value.indexOf(PUBLICATIE_STATUS.virusscanner) > -1)
            return "In behandeling";

        if (value.indexOf(PUBLICATIE_STATUS.virus) > -1 || value.indexOf(PUBLICATIE_STATUS.mappingfouten) > -1 )
            return "Fout";

        return this.capitalizeFirstLetter(this.removeLeadingUnderscores(value));
    }

    public sanitizeApiEndpoint = (apiEndpoint: string, controller: Controllers, id?: string, query?: string, toevoeging?: string) => {
        const sanitizedApiEndpoint = this.ensureNoSlash(apiEndpoint);
        const sanitizedController = this.ensureNoSlash(controller);
        let url = "";

        if (id)
            url = `${sanitizedApiEndpoint}/${sanitizedController}/${id}`;
        else
            url = `${sanitizedApiEndpoint}/${sanitizedController}`;

        if (query)
            url += `?${query}`;

        if (toevoeging)
            url += `${toevoeging}`;

        return url;
    }

    public sanitizeWaardelijstItem = (item: Identifier): Identifier => ({
        ...item,
        label: item.label?.split(' (')[0]
    })

    public sanitizeTrefwoorden = (value: string): string[] => value.split(',').map((char:string) => char.trim());

    public sanitizeLdId = (LdItem: any, context: any) => {
        const itemId = LdItem['@id'] ?? LdItem;

        const prefixKey = itemId.split(":")[0];
        const trailingId = itemId.split(":")[1];
        const prefixValue = context[prefixKey];

        return `${prefixValue}${trailingId}`;
    }

    public sanitizeLdPrefLabel = (item: any) => Array.isArray(item.prefLabel) ? item.prefLabel[0]['@value'] : item.prefLabel['@value'];

    public capitalizeFirstLetter = (value: string) => {
        if (!value)
            return ""

        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    public removeLeadingUnderscores = (value: string) => {
        if (value.indexOf("_") === 0)
            return value.substring(1);

        return value;
    }

    public extractIdentifier = (value: string) => {
        const parts = value.split("_");
        if (parts.length === 1)
            return value;

        return parts.slice(1).join("_");
    }

    public extractIdentifierFromPid = (pid?: string) => {
        return pid!.split('/').pop() ?? "";
    }

    public createDCNID = (source: string, uuid: string) => {
        return source + "-" + uuid;
    }
}
import { BaseClient } from "./baseClient";

export interface IDocumentApi {
  uploadDocument(locationHeader: string, file: File): Promise<any>;
  updateDocument(
    locationHeader: string,
    file: File,
    redenId: string
  ): Promise<any>;
  depubliceerSession(id: string, reden: string): Promise<any>;
}

export class MockDocumentApi extends BaseClient implements IDocumentApi {
  constructor() {
    super(
      new Headers({
        "Content-type": "application/json",
      }),
      process.env.REACT_APP_DOCUMENT_API_ENDPOINT as string
    );
  }

  uploadDocument(locationHeader: string, file: File): Promise<any> {
    return Promise.resolve({ response: new Response() });
  }
  updateDocument(
    locationHeader: string,
    file: File,
    redenId: string
  ): Promise<any> {
    return Promise.resolve({ response: new Response() });
  }

  async depubliceerSession(
    id: string,
    reden: string
  ): Promise<{ response: Response; json?: Document | undefined }> {
    return Promise.resolve({ response: new Response() });
  }
}

export class DocumentApi extends BaseClient implements IDocumentApi {
  constructor() {
    super(
      new Headers({
        "Content-type": "application/json",
      }),
      process.env.REACT_APP_DOCUMENT_API_ENDPOINT as string
    );
  }

  uploadDocument = (locationHeader: string, file: File) => {
    const body = new FormData();
    body.append("document", file);

    return fetch(locationHeader, {
      credentials: "include",
      body,
      method: "POST",
    }).then((response: Response) =>
      this.handleResponse("documenten", response)
    );
  };

  updateDocument = (locationHeader: string, file: File, redenId: string) => {
    const body = new FormData();
    body.append("document", file);

    return fetch(locationHeader, {
      credentials: "include",
      headers: new Headers({
        "x-reason": redenId,
      }),
      body,
      method: "PUT",
    }).then((response: Response) =>
      this.handleResponse("documenten", response)
    );
  };

  depubliceerSession = async (id: string, reden: string) => {
    return await this.api<Document>(
      "documenten",
      id,
      "DELETE",
      undefined,
      undefined,
      undefined,
      [{ key: "x-reason", value: reden }]
    );
  };
}

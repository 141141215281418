import { ToastType } from "../../interfaces/Toast";
import styles from "./styles.module.scss";

interface Props {
  type: ToastType;
  children?: any;
}

export function ToastAtom(props: Props) {
  const styleType = styles[`toaster--${props.type}`];
  const className = `${styles.toaster} ${styleType}`;

  return <div className={className}>{props.children}</div>;
}

import { logout } from "../store/reducers/auth";
import { getMeldingen } from "../store/reducers/meldingen";
import { setToast } from "../store/reducers/toast";

export function ReducerErrorToaster(thunkAPI: any, error: Error, message?: string) {
  thunkAPI.dispatch(
    setToast({
      autoClose: true,
      type: "error",
      message: {
        message: message ? message : isSessieVerlopen(error),
      },
    })
  );

  if(error.name === "401"){
    thunkAPI.dispatch(logout());
  }

  thunkAPI.dispatch(getMeldingen());
}

const isSessieVerlopen = (error: Error) => {
  return error.name === "401" ? "Sessie is verlopen. Log opnieuw in." : error.message;
}
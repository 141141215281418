import { Links } from "../../../content";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addForm, clear, setMode } from "../../../store/reducers/metadata";
import { selectAuth, selectMetadata } from "../../../store/selectors";
import { INavLink } from "../../interfaces/Link";
import { HeaderMolecule } from "../../molecules/header";

export function OverheidHeaderOrganism() {
  const dispatch = useAppDispatch();
  const stateMeta = useAppSelector(selectMetadata);

  const links: INavLink[] = [
    {
      ...Links.intern.start,
      selected:
        window.location.hash.startsWith(Links.intern.start.href) ||
        window.location.hash === "",
      authorized: false,
    },
    {
      ...Links.intern.documentenlijst,
      selected:
        window.location.hash.startsWith(Links.intern.documentenlijst.href) ||
        (window.location.hash.startsWith(Links.intern.aanleveren.href) &&
          stateMeta.mode === "update"),
      authorized: true,
    },
    {
      ...Links.intern.aanleveren,
      selected:
        window.location.hash.startsWith(Links.intern.aanleveren.href) &&
        stateMeta.mode !== "update",
      authorized: true,
      onClick: async function () {
        if (window.location.hash.startsWith(Links.intern.aanleveren.href)) return;

        dispatch(clear());
        dispatch(setMode("create"));
        await dispatch(addForm());
      },
    },
    {
      ...Links.intern.over,
      authorized: false,
      selected: window.location.hash.startsWith(Links.intern.over.href),
    },
    {
      ...Links.intern.apidocs,
      authorized: false,
      selected: window.location.hash.startsWith(Links.intern.apidocs.href),
    },
  ];

  const state = useAppSelector(selectAuth);
  const navlinks = state.isAuthenticated
    ? links
    : links.filter((link:INavLink) => link.authorized === false);

  return (
    <HeaderMolecule
      logo="/assets/images/overheid-logo.svg"
      subtitle="Platform Open Overheidsinformatie"
      links={navlinks}
    />
  );
}

import { Links, Teksten } from "../../../content";
import {
  ContainerAtom,
  HeadingH2Atom,
  HeadingH3Atom,
  LinkAtom,
  SpacerAtom
} from "../../atoms";
import { TypographyAtom } from "../../atoms/typography";

const HulpAlineaIndex = 1;

interface TitleTekstLink {
  titel: string;
  tekst: string[];
  linkTekst?: string;
}

export function OverOrganism() {
  const alinias = Teksten.over.alinia.map((alinia:TitleTekstLink, index:number) => {
    return (
      <div key={"over_tekst_"+index}>
        <HeadingH3Atom>{alinia.titel}</HeadingH3Atom>
        <TypographyAtom>{alinia.tekst}</TypographyAtom>
        {index === HulpAlineaIndex &&
          <>
            {Teksten.over.alinia[HulpAlineaIndex].linkTekst}
            <LinkAtom href={Links.extern.handleiding.href} text={Links.extern.handleiding.text} title={Links.extern.handleiding.title} newWindow/>
          </>}
        <SpacerAtom space={8} />
      </div>
    );
  });

  return (
    <ContainerAtom type="columns">
      <SpacerAtom space={16} />
      <HeadingH2Atom>{Teksten.over.titel}</HeadingH2Atom>
      <SpacerAtom space={8} />
      {alinias}
    </ContainerAtom>
  );
}

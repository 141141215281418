import { FileUploader } from "react-drag-drop-files";
import { ButtonLookALikeAtom } from "../button";
import styles from "./styles.module.scss";

interface Props {
  children: any;
  onFileChanged: (file?: File) => any;
  onClickFileInput?: (file?: File | string) => any;
  accept?: string[];
  disabled?: boolean;
  inputRef?: (ref: HTMLDivElement) => void;
  selectedInput?: File | string;
}

export function FileInputAtom(props: Props) {
  if (props.disabled) {
    return props.children;
  }

  return (
    <div
      className={styles.file_input}
      ref={(ref: HTMLDivElement) => {
        props.inputRef && ref && props.inputRef(ref);
      }}
    >
      <ButtonLookALikeAtom
        onClick={() => {
          if (props.onClickFileInput) props.onClickFileInput(props.selectedInput);
        }}
        id={"File_input"}
      >
        <FileUploader
          multiple={false}
          types={props.accept}
          handleChange={(file: File | undefined) => props.onFileChanged(file)}
        >
          {props.children}
        </FileUploader>
      </ButtonLookALikeAtom>
    </div>
  );
}
